import React from "react";

export default function Roles() {
  return (
    <div style={{ padding: "10px" }}>
      <h3>İstifadə Qaydaları</h3>
      <p>
        Kursline istifadə edərkən bütün istifadəçilər aşağıdakı qaydalara əməl
        etməlidirlər. Bu qaydalar Kursline icması üçün təhlükəsiz, hörmətli və
        xoş bir mühit təmin etmək üçün nəzərdə tutulub.
      </p>
      <span>1. Qeydiyyat və Hesabın İdarə Edilməsi</span>
      <p>
        - İstifadəçilər dəqiq və doğru məlumatla qeydiyyatdan keçməlidirlər. -
        Hər bir istifadəçinin yalnız bir hesabı ola bilər. Birdən çox hesab
        yaratmaq qadağandır. - Hesab məlumatlarınızı məxfi saxlamalı və üçüncü
        tərəflərlə paylaşmamalısınız.
      </p>
      <span>2. Məzmun Paylaşımı</span>
      <p>
        - Kursline-da paylaşılan bütün məzmunlar (kurs siyahıları, şərhlər,
        mesajlar və s.) dəqiq və aktual olmalıdır. - Yanlış, yalan və ya
        aldadıcı məlumatların paylaşılması qadağandır. - Müəllif hüquqları ilə
        qorunan məzmun yalnız qanuni hüququnuz olduqda paylaşıla bilər.
      </p>
      <span>3. Davranış Qaydaları</span>
      <p>
        - Digər istifadəçilərlə hörmətlə və nəzakətlə ünsiyyət qurmalısınız. -
        Təhqir, təhdid, zorakılıq və ya təhqiramiz davranışlara yol verilmir. -
        İrqçi, cinsiyyətçi, homofob və ya ayrı-seçkilik xarakterli hər cür
        ifadələr qadağandır.
      </p>
      <span>4. Təhlükəsizlik</span>
      <p>
        - Özünüzün və başqalarının təhlükəsizliyi üçün şəxsi məlumatlarınızı
        (ünvan, telefon nömrəsi, e-poçt və s.) paylaşmayın. - Başqalarının şəxsi
        məlumatlarının onların razılığı olmadan paylaşılması qəti qadağandır.
      </p>
      <spna>5. Spam və Reklam</spna>
      <p>
        - İstənməyən mesajlar (spam) və ya kommersiya xarakterli reklamlar
        qadağandır. - Proqram daxilində reklam etməzdən əvvəl Kursline-dan
        yazılı icazə almalısınız.
      </p>
      <spna>6. Qanunsuz Fəaliyyətlər</spna>
      <p>
        - Hər hansı qeyri-qanuni fəaliyyətlə məşğul olmaq və ya təbliğ etmək
        qəti qadağandır. - Tətbiq vasitəsilə qeyri-qanuni məhsul və ya
        xidmətlərin təşviqi qadağandır.
      </p>
      <span>7. Qanun pozuntuları və cəzalar</span>
      <p>
        - Bu qaydalara əməl etməyən istifadəçilər müvafiq tədbirlərlə
        üzləşəcəklər. Bunlara xəbərdarlıqlar, məzmunun silinməsi, hesabın
        dayandırılması və ya daimi qadağalar daxil ola bilər. -
        Kursline@kursline.az ünvanına qayda pozuntuları və ya qeyri-münasib
        davranışlar barədə məlumat verməklə moderator komandasına kömək edə
        bilərsiniz.
      </p>
      <span>8. Dəyişikliklər</span>
      <p>
        - Kursline bu qaydaları istənilən vaxt dəyişdirmək hüququnu özündə
        saxlayır. Dəyişikliklər tətbiqdə dərc edildikdən sonra qüvvəyə minəcək.
        Dəyişikliklər dərc edildikdən sonra tətbiqdən istifadənin davam
        etdirilməsi yeni qaydaları qəbul etdiyinizi bildirir.
      </p>
    </div>
  );
}
