import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../User/UserProfile.css";
import useAuth from "../../Hooks/useAuth";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import ClipLoader from "react-spinners/ClipLoader";
import { Helmet } from "react-helmet";
import userimg from "../../images/anonymous-avatar-icon-9.jpg";
import Swal from "sweetalert2";

function UserProfile() {
  const [userProfile, setUserProfile] = useState("");
  const { auth } = useAuth();
  const [formData, setFormData] = useState();
  const photo = useRef(null);
  const [nameerror, setNameerror] = useState("");
  const [Phoneerror, setPhoneerror] = useState();
  const [imgp, setImgp] = useState();
  const [value, setValue] = useState();
  const [activ, setActiv] = useState(true);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (auth.roles === 3) {
      setUserProfile(
        <React.Fragment>
          <Link to="/UserProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/CompanyProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    }
    axios
      .get(`https://api.kursline.az/GetUserbyId/${auth.user.userInfo.id}`, {
        headers: {
          Authorization: `Bearer ${auth.user.accsessToken}`,
        },
      })
      .then((res) => {
        setFormData(res.data);
        setValue(res.data.mobileNumber);
      });
  }, []);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImgp(imageUrl);
    if (file && file.size <= 3 * 1024 * 1024) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        photo: file,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;
    if (name.endsWith("Id")) {
      parsedValue = parseInt(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setActiv(false);
    const formData1 = new FormData();
    formData1.append("fullName", formData.fullName);
    formData1.append("gender", formData.gender);
    formData1.append("mobileNumber", value);
    formData1.append("photos", formData.photo);
    axios
      .post(
        `https://api.kursline.az/Update-user/${auth.user.userInfo.id}`,
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then((res) => {
        // window.location.reload();
        setActiv(true);
        Swal.fire({
          icon: "success",
          allowOutsideClick: true,
          title: "Uğurlu",
          text: "Məlumatlarınız uğurla yeniləndi!",
        });
      })
      .catch((error) => {
        setActiv(true);
        if (error.response.data.code !== "SERVER_ERROR") {
          if (error.response.status === 400) {
            const errorDetails = error.response.data.details;
            setNameerror(errorDetails.FullName);
            setPhoneerror(errorDetails.MobileNumber);
            setPhoneerror(JSON.parse(error.response.data.details.Phone));
          }
        }
      });
  };

  const handleButtonClick = () => {
    Swal.fire({
      title: "Əminsən?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr, ləğv et!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  };

  const Notification = ({ message }) => {
    return (
      <div
        className="notification"
        style={{
          width: "150px",
          height: "100px",
          backgroundColor: "whitesmoke",
          position: "absolute",
          top: "300px",
          left: "40%",
          textAlign: "center",
          display: "none",
        }}
      >
        <p>{message}</p>
      </div>
    );
  };

  return (
    <div className="bs-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <div className="user-navbar">
        {userProfile}
        {/* <Link to="/UserUpdatetoBiznes" className="user-navbar-announcements">
          Mənim elanlarım
        </Link> */}
      </div>
      <div className="userProfile">
        <div className="userProfileLeft">
          <input
            type="file"
            id="Adınız"
            name="photo"
            style={{ display: "none" }}
            onChange={handlePhotoChange}
            ref={photo}
          />
          <img
            src={
              formData?.photo instanceof File
                ? imgp
                : formData?.photo
                ? formData?.photo
                : userimg
            }
            alt="kursline"
            onClick={() => photo.current.click()}
          />
        </div>
        <div className="userProfileRight">
          <span className="error">{Phoneerror}</span>
          <form onSubmit={handleSubmit} className="text-form">
            <div className="text-items">
              <label htmlFor="Adınız">
                {formData?.fullName ? (
                  "Ad və soyad"
                ) : nameerror ? (
                  <span className="error">{nameerror}</span>
                ) : (
                  "Ad və soyad"
                )}
              </label>
              <input
                style={
                  formData?.fullName
                    ? {}
                    : nameerror
                    ? { border: "1px solid red" }
                    : {}
                }
                type="text"
                id="Adınız"
                name="fullName"
                value={formData?.fullName}
                onChange={handleChange}
              />
            </div>
            <div className="text-items">
              <label htmlFor="UserPhone">
                {" "}
                {value && !isValidPhoneNumber(value) ? (
                  <span className="error">*Düzgün formatda deyil</span>
                ) : Phoneerror ? (
                  <span className="error">{Phoneerror}</span>
                ) : (
                  "Telefon"
                )}
              </label>
              <Input
                country="AZ"
                international
                withCountryCallingCode
                name="mobileNumber"
                value={value}
                onChange={setValue}
                required
                style={
                  value && !isValidPhoneNumber(value)
                    ? { border: "1px solid red" }
                    : {}
                }
              />
            </div>

            <button type="button" onClick={handleButtonClick}>
              {!activ ? (
                <ClipLoader
                  color="white"
                  cssOverride={{}}
                  loading={!activ}
                  size={18}
                  speedMultiplier={1}
                />
              ) : (
                "Yaddaşda saxla"
              )}
            </button>
          </form>
        </div>
      </div>
      {notification && <Notification message={notification} />}
    </div>
  );
}

export default UserProfile;
