import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Filter from "../../components/Filter";
import Card from "../../components/Card";
import "./Home.css";
import useAuth from "../../Hooks/useAuth";
import Reklamlar from "../../components/Reklamlar";
import { Helmet } from "react-helmet-async";

export default function Home() {
  const [search, setSearch] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [regionId, setRegionId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [isOnlineId, setIsOnlineId] = useState("");
  const controller = useRef();
  const { auth } = useAuth();
  const [announcements, setAnnouncements] = useState([]);
  const [vipAnnouncements, setVipannouncments] = useState();
  const [numAnnouncements] = useState(50);
  const [ofset, setOfset] = useState(0);
  const GetAnons = () => {
    if (auth.user !== undefined) {
      axios
        .get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${auth.user.userInfo.id}`
        )
        .then(response => {
          setVipannouncments(response.data.vipAnnouncements);
          setAnnouncements(response.data.announcemenets);
        })
        .catch(error => {
          // console.log(error);
        });
    } else {
      axios
        .get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${0}`
        )
        .then(response => {
          setVipannouncments(response.data.vipAnnouncements);
          setAnnouncements(response.data.announcemenets);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };
  const GetAnnouncements = async () => {
    controller.current = new AbortController();
    const signal = controller?.current?.signal;
    if (auth.user !== undefined) {
      if (controller?.current) {
        controller?.current.abort();
      }
      //RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}
      axios
        .get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${auth.user.userInfo.id}`,
          signal
        )
        .then(response => {
          setVipannouncments(response.data.vipAnnouncements);
          if (announcements !== null) {
            setAnnouncements([
              ...announcements,
              ...response.data.announcemenets,
            ]);
          } else {
            setAnnouncements(response.data.announcemenets);
          }
        })
        .catch(error => {
          // console.log(error);
        });
    } else {
      axios
        .get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${0}`,
          signal
        )
        .then(response => {
          // if (response.data.total == 0) {
          //   console.log(announcements.length);
          //   console.log(ofset);
          //   if (ofset > announcements.length + 10) {
          //     setOfset(prev => prev - 10);
          //   }
          // }
          setVipannouncments(response.data.vipAnnouncements);

          if (announcements !== null) {
            setAnnouncements([
              ...announcements,
              ...response.data.announcemenets,
            ]);
          } else {
            setAnnouncements(response.data.announcemenets);
          }
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };

  const sifirla = () => {
    setOfset(0);
    setSearch("");
    setMinPrice("");
    setMaxPrice("");
    setRegionId("");
    setCategoryId("");
    setStatusId("");
    setIsOnlineId("");
    GetAnons();
  };

  useEffect(() => {
    GetAnnouncements();
  }, [ofset]);
  //scroll event listener
  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setOfset(prev => prev + 50);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Kursline - kurs elanları</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <link rel="canonical" href="https://kursline.az/" />
      </Helmet>
      <div className="home-container-fluid">
        <Filter
          setAnnouncements={setAnnouncements}
          setCategoryId={setCategoryId}
          categoryId={categoryId}
          setMaxPrice={setMaxPrice}
          maxPrice={maxPrice}
          setMinPrice={setMinPrice}
          minPrice={minPrice}
          setIsOnlineId={setIsOnlineId}
          isOnlineId={isOnlineId}
          setSearch={setSearch}
          search={search}
          setRegionId={setRegionId}
          regionId={regionId}
          setStatusId={setStatusId}
          statusId={statusId}
          setOfset={setOfset}
          sifirla={sifirla}
        />
      </div>
      <div className="nullzoneisvip">
        {" "}
        <Reklamlar />
      </div>
      {/* <hr
        style={{
          border: "none",
          width: "100%",
          height: "2px",
          backgroundColor: "green",
        }}
      /> */}
      <div className="home-main-body">
        <div className="home-reklam-cont"></div>
        <div className="home-elanlar-cont">
          {" "}
          <div className="isvip">
            <div className="vip">
              <h3>VIP Elanlar</h3>
            </div>
          </div>
          <div className="cards">
            {vipAnnouncements &&
              vipAnnouncements.map(announcement => (
                <Card key={announcement.id} announcement={announcement} />
              ))}
          </div>
          <div className="isvip mt-3">
            <div className="vip">
              <h3>Son Elanlar</h3>
            </div>
          </div>
          <div className="cards">
            {announcements &&
              announcements.map(announcement => (
                <Card key={announcement.id} announcement={announcement} />
              ))}
          </div>
        </div>
        <div className="home-reklam-cont"></div>
      </div>
    </div>
  );
}
