import { useState } from "react";

const Select = ({ label, id, options, value, setValue }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(value !== "");

  return (
    <div className={` ${isFocused ? "focused" : ""} form-input`}>
      {/* <label htmlFor={id}>{label}</label> */}
      <select
        className="form-select"
        id={id}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={e => setValue(e.target.value)}
      >
        <option value="">{label}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
export default Select;
