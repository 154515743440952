import React, { useEffect, useState } from "react";
import "../layout/Layout2.css";
import Checkbox from "@mui/material/Checkbox";
import logo from "../../images/kursline (1).png";
import listimg from "../../images/advertisement.png";
import vipimg from "../../images/newspaper.png";
import ordimg from "../../images/online-transfer.png";
import compimg from "../../images/building.png";
import msimg from "../../images/email.png";
import light from "../../images/night-mode (2).png";
import dark from "../../images/night-mode (1).png";
import categ from "../../images/category.png";
import exit from "../../images/log-out.png";
import comment from "../../images/chat-bubble.png";
import home from "../../images/home-svgrepo-com.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import axios from "axios";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function Navbar(prop) {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);
  const [mbCheck, setMbCheck] = useState(false);
  const handleLogOut = () => {
    setAuth({});
    localStorage.clear();
    navigate("/", { replace: true });
  };
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
    localStorage.setItem("mode", isDarkMode);
    prop.mode(!isDarkMode);
  };

  useEffect(() => {
    axios
      .get(`https://api.kursline.az/checkupdate`, {
        headers: { Accept: `*/*` },
      })
      .then(function (res) {
        console.log(res.data);
        setMbCheck(res.data.isUpdate);
      });
  }, []);

  const changeM = async () => {
    axios
      .post(`https://api.kursline.az/Admin/ChangeMobileVersion`, null, {
        headers: { Authorization: `Bearer ${auth.user.accsessToken}` },
      })
      .then(function (res) {
        console.log(res.data);
        setMbCheck(res.data.version);
      });
  };
  return (
    <div
      className={`sidebar ${isMenuOpen ? "open" : "close"} ${
        isDarkMode ? "dark" : ""
      }`}
    >
      <i
        className="fa-solid fa-bars menu-bar-icon"
        style={{ color: "#52c926;" }}
        onClick={toggleMenu}
      ></i>
      <div
        className={`menu-main ${isMenuOpen ? "open2" : "close"} ${
          isDarkMode ? "dark" : ""
        }`}
        style={
          isDarkMode
            ? {
                background:
                  "url('https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg')",
              }
            : {
                background:
                  "url('https://i.pinimg.com/736x/8e/6c/06/8e6c064f57f94838263d7ba9ad80f353.jpg')",
              }
        }
      >
        <div
          className="menu-backround"
          style={
            isDarkMode
              ? { background: "rgb(11 41 72 / 73%)" }
              : { background: "rgba(255, 255, 255, 60%)" }
          }
        >
          <div className="menu-toggle" onClick={toggleMenu}>
            <img src={logo} style={{ width: "95px", height: "85px" }} alt="" />
            <span style={{ color: "green" }}>KursLine</span>
          </div>
          <ul className="menu">
            <li>
              <Link to="/">
                <img src={home} alt="" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/Admin/dashboard">
                <img src={listimg} alt="" />
                Elanlar
              </Link>
            </li>
            <li>
              <Link to="/Admin/Commentlər">
                <img src={comment} alt="" />
                Commentlər
              </Link>
            </li>
            <li>
              <Link to="/Admin/Sirketler">
                <img src={compimg} alt="" />
                Şirkətlər
              </Link>
            </li>
            <li>
              <Link to="/Admin/Sikayetler">
                <img src={msimg} alt="" />
                Mesajlar
              </Link>
            </li>
            <li>
              <Link to="/Admin/Reklamlar">
                <img src={vipimg} alt="" />
                Reklamlar
              </Link>
            </li>
            <li>
              <Link to="/Admin/Odəmələr">
                <img src={ordimg} alt="" />
                Ödəmələr
              </Link>
            </li>
            <li>
              <Link to="/Admin/Kateqoriyalar">
                <img src={categ} alt="" />
                Kateqoriyalar
              </Link>
            </li>
            <li onClick={handleLogOut} style={{ cursor: "pointer" }}>
              <img src={exit} alt="" />
              Çıxış
            </li>
          </ul>
          <div className="dark-mode-toggle" style={{ alignItems: "center" }}>
            <label htmlFor="chmob" style={{ marginRight: "5px" }}>
              Mobil
            </label>
            <Checkbox
              checked={mbCheck}
              id="chmob"
              onChange={changeM}
              inputProps={{ "aria-label": "Checkbox" }}
              size="small"
            />
          </div>
          <div className="dark-mode-toggle" onClick={toggleDarkMode}>
            <img src={isDarkMode ? light : dark} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
