import React, { useState, useEffect } from "react";
import axios from "axios";
import Input from "./Input";
import Select from "./Select";
import "./Filter.css";
import useAuth from "../Hooks/useAuth";
import ClipLoader from "react-spinners/ClipLoader";
export default function Filter({
  setAnnouncements,
  search,
  setSearch,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  regionId,
  setRegionId,
  categoryId,
  setCategoryId,
  statusId,
  setStatusId,
  isOnlineId,
  setIsOnlineId,
  setOfset,
  sifirla,
}) {
  const { auth } = useAuth();
  const [regions, setRegions] = useState([]);
  const [category, setCategory] = useState([]);
  const [online, setOnline] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [flshow, setflshow] = useState(false);
  const [activ, setActiv] = useState(true);
  const handlesifirla = e => {
    setAnnouncements([]);
    e.preventDefault();
    sifirla();
  };
  // const [ofset, setOfset] = useState(0);
  useEffect(() => {
    axios
      .get(`https://api.kursline.az/GetAnnouncementOptions`)
      .then(response => {
        setRegions(
          response.data.regions.map(region => ({
            label: region.regionName,
            value: region.regionId,
          }))
        );
        setCategory(
          response.data.categories.map(x => ({
            label: x.categoryName,
            value: x.categoryId,
          }))
        );
        setOnline(
          response.data.isOnlines.map(isOnlines => ({
            label: isOnlines.isOnlineName,
            value: isOnlines.isOnlineId,
          }))
        );
        setStatuses(
          response.data.statuses
            .filter(status => status.statusId !== 3)
            .map(status => ({
              label: status.statusName,
              value: status.statusId,
            }))
        );
      })
      .catch(error => {
        // console.log(error);
      });
  }, []);

  const handleFilterSubmit = e => {
    e.preventDefault();
    setActiv(false);
    setAnnouncements([]);
    setOfset(0);
    axios
      .get(
        `https://api.kursline.az/GetAnnouncements?limit=${50}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}`
      )
      .then(response => {
        setAnnouncements(response.data.announcemenets);
        setActiv(true);
      })
      .catch(error => {
        console.log(error);
        setActiv(true);
      });
  };
  const SearchChange = e => {
    setActiv(false);
    setAnnouncements([]);
    setOfset(0);
    axios
      .get(
        `https://api.kursline.az/GetAnnouncements?limit=${50}&RegionId=${regionId}&CategoryId=${categoryId}&search=${
          e.target.value
        }&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}`
      )
      .then(response => {
        setAnnouncements(response.data.announcemenets);
        setActiv(true);
      })
      .catch(error => {
        console.log(error);
        setActiv(true);
      });
  };
  const filterShow = e => {
    setflshow(!flshow);
  };
  return (
    <>
      <div className="new-ad-add container">
        <div className="search-bar-search">
          {/* <form> */}
          <form>
            <input
              type="search"
              placeholder="Axtarış..."
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                SearchChange(e);
              }}
            />
          </form>
        </div>
        <div className="flt-btn mobaxtar">
          <button
            disabled={!activ}
            onClick={handleFilterSubmit}
            className="axtar btn btn-success"
          >
            {!activ ? (
              <ClipLoader
                color="#36d7b7"
                cssOverride={{}}
                loading={!activ}
                size={18}
                speedMultiplier={1}
              />
            ) : (
              "Axtar..."
            )}
          </button>
          <button className="btn btn-success" onClick={filterShow}>
            <i class="fa-solid fa-filter" style={{ color: "white" }}></i>
          </button>
        </div>
      </div>
      <form onSubmit={handleFilterSubmit}>
        <div
          className={
            !flshow ? "main container  fldeactiv" : "main container filter"
          }
        >
          <Select
            label="Region seç"
            id="Region seç"
            className="regionsec"
            options={regions}
            value={regionId}
            setValue={setRegionId}
          />
          <Select
            label="Kateqoriya seç"
            id="selectOption"
            options={category}
            value={categoryId}
            setValue={setCategoryId}
          />
          <Select
            label="Online/ənənəvi"
            id="selectOption"
            options={online}
            value={isOnlineId}
            setValue={setIsOnlineId}
          />
          <Select
            label="Kurs/Repititor"
            id="selectOption"
            options={statuses}
            value={statusId}
            setValue={setStatusId}
          />
          <Input
            label="Minimum"
            id="minPrice"
            value={minPrice}
            setValue={setMinPrice}
          />
          <Input
            label="Maximum"
            id="maxPrice"
            value={maxPrice}
            setValue={setMaxPrice}
          />
          {/* <button className="axtar btn btn-success" onClick={axtar}> */}

          <button type="submit" className=" mob btn btn-success">
            Axtar...
          </button>
          <button
            type="button"
            onClick={handlesifirla}
            className="btn-zero btn btn-light"
          >
            Sıfırla
          </button>
        </div>
      </form>
    </>
  );
}
