import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../Business/BusinessProfile.css";
import useAuth from "../../Hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import ClipLoader from "react-spinners/ClipLoader";
import { Helmet } from "react-helmet";
import userimg from "../../images/anonymous-avatar-icon-9.jpg";
import Swal from "sweetalert2";

const initialFormData = {};

function BusinessProfile() {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [formData, setFormData] = useState(initialFormData);
  const [option, setOption] = useState();
  const [token, setToken] = useState();
  const photo = useRef(null);
  const [imgp, setImgp] = useState();
  const [nameerror, setNameerror] = useState("");
  const [Phoneerror, setPhoneerror] = useState("");
  const [Abouterror, setAbouterror] = useState("");
  const [regionerror, setRegionerror] = useState("");
  const [compnameerror, setCompnameerror] = useState("");
  const [caterror, setCaterror] = useState("");
  const [statuserror, setStatuserror] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [value, setValue] = useState();
  const [activ, setActiv] = useState(true);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImgp(imageUrl);
    if (file && file.size <= 3 * 1024 * 1024) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        photo: file,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;
    if (name.endsWith("Id")) {
      parsedValue = parseInt(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const getuserbyId = () => {
    axios
      .get(`https://api.kursline.az/GetUserbyId/${auth.user.userInfo.id}`, {
        headers: {
          Authorization: `Bearer ${auth.user.accsessToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setFormData(res.data);
        setValue(res.data.mobileNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (auth.roles === 3) {
      setUserProfile(
        <React.Fragment>
          <Link to="/UserProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else if (auth.roles === 4) {
      setUserProfile(
        <React.Fragment>
          <Link to="/admin/dashboard" className="user-navbar-profile">
            Admin panel
          </Link>
          <Link
            to="/CompanyProfile"
            className="user-navbar-profile"
            style={{ marginLeft: "20px" }}
          >
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/CompanyProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    }
    setToken(auth.user.accsessToken);
    axios.get("https://api.kursline.az/GetRegisterDatas").then(function (res) {
      setOption(res.data);
    });
    getuserbyId();
  }, [auth]);

  const handleSubmit = async () => {
    setActiv(false);
    console.log(formData);
    const formData1 = new FormData();
    formData1.append("companyAbout", formData.companyAbout);
    formData1.append("companyCategoryId", formData.companyCategoryId);
    formData1.append("companyName", formData.companyName);
    formData1.append("companyRegionId", formData.companyRegionId);
    formData1.append("userFullName", formData.fullName);
    formData1.append("gender", formData.gender);
    formData1.append("mobileNumber", value);
    formData1.append("photos", formData.photo);
    formData1.append("CompanyStatusId", formData.userStatusId);

    axios
      .post(
        `https://api.kursline.az/Update-Company/${auth.user.userInfo.id}`,
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        setActiv(true);
        console.log(res.status);
        if (res.status === 200) {
          getuserbyId();
          Swal.fire({
            title: "Uğurlu!",
            text: "Məlumatlarınız yeniləndi.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setActiv(true);
        if (error.response.data.code !== "SERVER_ERROR") {
          if (error.response.status === 400) {
            const errorDetails = error.response.data.details;
            setNameerror(errorDetails.UserFullName);
            setPhoneerror(errorDetails.MobileNumber);
            setCompnameerror(errorDetails.CompanyName);
            setAbouterror(errorDetails.CompanyAbout);
            setRegionerror(errorDetails.CompanyRegionId);
            setCaterror(errorDetails.CompanyCategoryId);
            setStatuserror(errorDetails.CompanyStatusId);
          }
        }
      });
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Əminsiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Yox, ləğv et",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  };

  const DeleteProfil = async () => {
    Swal.fire({
      title: `Əminsiniz?`,
      text: "Hesabınızı silmək istədiyinizdən əminsiniz? Bu işlem geri alınamaz!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/User/DeleteUser?id=${auth.user.userInfo.id}`,
            {},
            {
              headers: { Authorization: `Bearer ${auth.user.accsessToken}` },
            }
          )
          .then(function (res) {
            if (res.status === 200) {
              if (res.data) {
                setAuth("");
                localStorage.clear();
                navigate("/", { replace: true });
              }
            } else {
              console.log(res);
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    });
  };

  return (
    <div className="bs-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:type"
          property="og:type"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>

      <div className="user-navbar">
        {userProfile}
        <Link to="/MyAnnouncments" className="user-navbar-announcements">
          Mənim elanlarım
        </Link>
      </div>

      <div className="businessProfile">
        <form onSubmit={handleSubmit}>
          <div className="frm-first">
            <div className="first-img">
              <input
                type="file"
                id="Adınız"
                name="photo"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
                ref={photo}
              />
              <img
                src={
                  formData.photo instanceof File
                    ? imgp
                    : formData.photo
                    ? formData.photo
                    : userimg
                }
                alt="kurline, user,repititor,business"
                onClick={() => photo.current.click()}
              />
            </div>
            <div className="first-info">
              <div className="fr-items">
                <label htmlFor="Adınız">
                  {formData.fullName ? (
                    "Ad və soyad"
                  ) : nameerror ? (
                    <span className="error">*{nameerror}</span>
                  ) : (
                    "Ad və soyad"
                  )}
                </label>
                <input
                  style={
                    formData.fullName
                      ? {}
                      : nameerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  id="Adınız"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="fr-items">
                <label htmlFor="Şirkət adı">
                  {formData.companyName ? (
                    "Şirkət adı"
                  ) : compnameerror ? (
                    <span className="error">*{compnameerror}</span>
                  ) : (
                    "Şirkət adı"
                  )}
                </label>
                <input
                  style={
                    formData.companyName
                      ? {}
                      : compnameerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  id="Adınız"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="fr-items">
                <label htmlFor="UserPhone">
                  {value && !isValidPhoneNumber(value) ? (
                    <span className="error">*Düzgün fotmatda deyil</span>
                  ) : Phoneerror ? (
                    <span className="error">*{Phoneerror}</span>
                  ) : (
                    "Telefon"
                  )}
                </label>
                <Input
                  country="AZ"
                  international
                  withCountryCallingCode
                  name="mobileNumber"
                  value={value}
                  onChange={setValue}
                  required
                  disabled
                  style={
                    value && !isValidPhoneNumber(value)
                      ? { border: "1px solid red" }
                      : {}
                  }
                />
              </div>
            </div>
          </div>
          <div className="frm-second">
            <div className="second-selects">
              <select
                name="companyRegionId"
                style={
                  formData.companyRegionId
                    ? {}
                    : regionerror
                    ? { border: "1px solid red" }
                    : {}
                }
                id=""
                onChange={handleChange}
              >
                <option defaultValue="">Region seç</option>
                {option &&
                  option.regions.map((e) => (
                    <option
                      key={e.regionId}
                      selected={e.regionId === formData.companyRegionId}
                      defaultValue={e.regionId}
                      value={e.regionId}
                    >
                      {e.regionName}
                    </option>
                  ))}
              </select>
              <select
                name="companyCategoryId"
                id=""
                style={
                  formData.companyCategoryId
                    ? {}
                    : caterror
                    ? { border: "1px solid red" }
                    : {}
                }
                onChange={handleChange}
              >
                <option defaultValue="">Kateqoriya seç</option>
                {option &&
                  option.categories.map((e) => (
                    <option
                      key={e.categoryId}
                      selected={e.categoryId === formData.companyCategoryId}
                      defaultValue={parseInt(e.categoryId)}
                      value={e.categoryId}
                    >
                      {e.categoryName}
                    </option>
                  ))}
              </select>
              <select
                name="userStatusId"
                className="rejimSelect"
                onChange={handleChange}
                style={
                  formData.userStatusId
                    ? {}
                    : statuserror
                    ? { border: "1px solid red" }
                    : {}
                }
              >
                <option defaultValue="">Status seç</option>
                {option &&
                  option.statuses.map((e) => (
                    <option
                      key={e.statusId}
                      selected={e.statusId === formData.userStatusId}
                      defaultValue={parseInt(e.statusId)}
                      value={e.statusId}
                    >
                      {e.statusName}
                    </option>
                  ))}
              </select>
            </div>
            <div className="text-arr">
              <label htmlFor="UserPhone">
                {formData.companyAbout ? (
                  "Şirkət haqqında"
                ) : Abouterror ? (
                  <span className="error">*{Abouterror}</span>
                ) : (
                  "Şirkət haqqında"
                )}
              </label>
              <textarea
                style={
                  formData.companyAbout
                    ? {}
                    : Abouterror
                    ? { border: "1px solid red" }
                    : {}
                }
                name="companyAbout"
                value={formData.companyAbout}
                onChange={handleChange}
              ></textarea>
              <button type="button" onClick={handleButtonClick}>
                {!activ ? (
                  <ClipLoader
                    color="#36d7b7"
                    cssOverride={{}}
                    loading={!activ}
                    size={18}
                    speedMultiplier={1}
                  />
                ) : (
                  "Yaddaşda saxla"
                )}
              </button>
              {/* <button
                type="button"
                className="bg-danger"
                onClick={DeleteProfil}
              >
                Hesabı sil
              </button> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BusinessProfile;
