import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../components/reklamlar.css";
import axios from "axios";
import Slider from "react-slick";

function Reklamlar() {
  const [size, setSize] = useState(40);
  const [reklam, setReklam] = useState();

  var settings = {
    dots: false,
    className: "center",
    // centerMode: true,
    infinite: true,
    autoplay: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios
      .get("https://api.kursline.az/GetReklamlar")
      .then((res) => {
        console.log(res.data);
        setReklam(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // const isMobile = window.innerWidth <= 768; // Adjust the threshold as needed for your mobile design
    // if (isMobile) {
    //   setSize(80);
    // }
  }, []);
  // <Carousel
  //   autoPlay={true}
  //   dynamicHeight={true}
  //   emulateTouch={true}
  //   swipeable={true}
  //   infiniteLoop={true}
  //   selectedItem={0}
  //   showArrows={false}
  //   showIndicators={false}
  //   showThumbs={false}
  //   stopOnHover={true}
  //   showStatus={true}
  //   centerMode
  //   centerSlidePercentage={size}
  //   interval={3000}
  // >

  // </Carousel>
  return (
    <Slider {...settings}>
      {reklam &&
        reklam.map((e) => (
          <div
            className="rek-main"
            key={e.reklamId}
            style={{ height: "200px" }}
          >
            <img
              className="rek-image"
              src={e.reklamPhoto}
              alt="kursline kurs repetitor reklam kurslar"
            />
            {/* <p className="reklam-content">
              <Link to={e.reklamLink} target="_blank">
                {e.reklamText.substring(0, 10)}
              </Link>
            </p> */}
          </div>
        ))}
    </Slider>
  );
}

export default Reklamlar;
