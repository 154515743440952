import React from "react";

export default function About() {
  return (
    <div style={{ padding: "10px" }}>
      <h4> Haqqımızda</h4>
      <p>
        Kursline.az təhsil dünyasında inqilab etmək üçün yaradılmış kurs axtarış
        platformasıdır. Məqsədimiz insanlara istədikləri kursları asanlıqla
        tapmaq və özlərini inkişaf etdirmək imkanlarından istifadə etməkdir.
        Kursline.az olaraq biz təhsilin insanların həyatına transformativ təsir
        göstərməsi üçün çalışırıq. Missiyamız Missiyamız insanlara təhsil
        sahəsində saysız-hesabsız imkanlar və məlumatlar çatdırmaqla, fərdlərə
        öz istedadlarını inkişaf etdirmək və məqsədlərinə çatmaqda kömək
        etməkdir. Kursline.az kurs axtaranlar və kurs təminatçıları arasında
        güclü körpü yaradır, təhsili daha əlçatan və fərdiləşdirir. Niyə
        Kursline.az? Kursline.az müxtəlif təhsil sahələrindən minlərlə kursa
        çıxışı təklif edir. Hər kəsin öz maraqlarına uyğun kurs tapa bilməsini
        təmin etmək üçün platformamızda incəsənət və dizayn, texnologiya, dil
        öyrənmə, biznes və s. üzrə kurslarımız var. Kursline.az-ın istifadəçi
        dostu interfeysi sayəsində kurs axtarış prosesi asan və sürətlidir.
        İstifadəçi Dəstəyi Kursline.az olaraq istifadəçilərimizin məmnuniyyəti
        və uğuru bizim prioritetimizdir. Kursları axtararkən və ya
        platformamızdan istifadə edərkən hər hansı probleminiz varsa, peşəkar və
        mehriban müştəri dəstəyi komandamızla əlaqə saxlaya bilərsiniz. Biz sizə
        ən yaxşı xidməti təklif etmək üçün buradayıq. Kursline.az ilə təhsil
        səyahətinizdə bir addım atın və arzuladığınız kursu tapın. Gələcəyə bir
        addım daha yaxın olmaq üçün bu gün qeydiyyatdan keçin
      </p>
      <p>
        Servisin inzibatçılığını Azərbaycan Respublikasının qanunvericiliyinə
        uyğun olaraq yaradılmış və qeydiyyatdan keçmiş “EDUCATOR LİNE MMC”
        Şirkəti (VÖEN: 1308291881) həyata keçirir. Servisə dair bütün mülkiyyət
        hüquqları müstəsna olaraq Şirkətə aiddir.
      </p>
    </div>
  );
}
