import React from "react";

export default function UserRole() {
  return (
    <div style={{ padding: "10px", paddingBottom: "50px" }}>
      <h3>İstifadəçi Qaydaları və Şərtləri</h3>
      <p>
        Bu İstifadəçi Şərtləri ("Şərtlər") sizinlə Kursline ("Tətbiq") arasında
        qanuni qüvvəyə malik müqaviləni təşkil edir. Kursline-a daxil olmaqla və
        ya istifadə etməklə siz bu Şərtlərə əməl etməyə razısınız. Bu Şərtlərlə
        razı deyilsinizsə, Tətbiqdən istifadə etməyin.
      </p>
      <span>1. Qeydiyyat və Hesab</span>
      <span>1.1 Qeydiyyat</span>
      <p>
        Proqramdan istifadə etmək üçün qeydiyyatdan keçməli və ya telefon
        nömrənizlə daxil olmalısınız. Siz qeydiyyat zamanı təqdim etdiyiniz
        məlumatların dəqiq, aktual və tam olduğuna zəmanət verirsiniz.
      </p>
      <span>1.2 Hesabın Təhlükəsizliyi</span>
      <p>
        Hesabınızın təhlükəsizliyinə cavabdehsiniz. Hesab məlumatlarınızı məxfi
        saxlamalı və icazəsiz girişin qarşısını almalısınız. Hesabınız altında
        baş verən bütün fəaliyyətlərə görə məsuliyyət daşıyırsınız.
      </p>
      <span>2. Xidmətlər 2.1 Ümumi</span>
      <p>
        Kursline kurs siyahılarının axtarışı və paylaşılması üçün xidmətlər
        təklif edir. Bu xidmətlər pulsuz təqdim olunur.
      </p>
      <span>2.2 İstifadə Qaydaları</span>
      <p>
        Siz Tətbiqdən istifadə edərkən bütün qüvvədə olan qanunlara və bu
        Şərtlərə əməl etməyə razılaşırsınız. Aşağıdakı hərəkətlər qadağandır: -
        Yanlış və ya yanlış məlumatların verilməsi. - Başqalarının hüquqlarını
        pozmaq. - Spam və ya istənməyən mesajların göndərilməsi. - Zərərli
        proqram və ya virusların yayılması.
      </p>
      <span> 3. İstifadəçi məzmunu 3.1 Məzmun Paylaşımı</span>
      <p>
        Tətbiq vasitəsilə paylaşdığınız bütün məzmuna (mətn, şəkillər və s.)
        görə yalnız siz məsuliyyət daşıyırsınız. Kursline paylaşdığınız məzmuna
        nəzarət etmir və buna görə heç bir məsuliyyət daşımır.
      </p>
      <span>3.2 Lisenziya</span>
      <p>
        Tətbiq vasitəsilə məzmunu paylaşmaqla siz Kursline-a bu cür məzmunu
        istifadə etmək, çoxaltmaq, yaymaq və göstərmək üçün dünya miqyasında,
        qeyri-eksklüziv, royaltisiz, sublisenziyalı və ötürülə bilən lisenziya
        verirsiniz.
      </p>
      <span>4. Məxfilik</span>
      <p>
        Kursline sizin məxfiliyinizi qiymətləndirir və Məxfilik Siyasətinə uyğun
        olaraq telefon nömrəniz kimi şəxsi məlumatlarınızı emal edir. Məxfilik
        Siyasətimizi nəzərdən keçirə bilərsiniz.
      </p>
      <span>5. Məsuliyyətdən imtina</span>
      <p>
        Kursline Tətbiqin fasiləsiz və ya səhvsiz olacağına zəmanət vermir.
        Tətbiq "olduğu kimi" və "mövcud olduğu kimi" təqdim olunur. Kursline
        Tətbiqdən istifadə nəticəsində yaranan hər hansı dolayı, birbaşa,
        təsadüfi, xüsusi və ya cəza zərərlərinə görə məsuliyyət daşımır.
      </p>
      <span>6. Dəyişikliklər</span>
      <p>
        Kursline istənilən vaxt bu Şərtlərə dəyişiklik etmək hüququnu özündə
        saxlayır. Dəyişikliklər Tətbiqdə dərc edildikdən sonra qüvvəyə minəcək.
        Dəyişikliklər dərc edildikdən sonra Proqramdan davamlı istifadəniz yeni
        Şərtləri qəbul etdiyinizi bildirir.
      </p>
      <span>7. Əlaqə</span>
      <p>
        Bu Şərtlərlə bağlı hər hansı sualınız varsa, kursline@kursline.az
        elektron poçt ünvanı ilə bizimlə əlaqə saxlayın.
      </p>
    </div>
  );
}
