import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import userimg from "../../images/anonymous-avatar-icon-9.jpg";
const initialFormData = {};
function UpdateUsertoBisnes() {
  const { auth, setAuth } = useAuth();
  const [formData, setFormData] = useState(initialFormData);
  const [option, setOption] = useState();
  const [token, setToken] = useState();
  const photo = useRef(null);
  const [imgp, setImgp] = useState();
  const [nameerror, setNameerror] = useState("");
  const [Phoneerror, setPhoneerror] = useState("");
  const [Abouterror, setAbouterror] = useState("");
  const [regionerror, setRegionerror] = useState("");
  const [compnameerror, setCompnameerror] = useState("");
  const [caterror, setCaterror] = useState("");
  const [statuserror, setStatuserror] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const navigate = useNavigate();
  const headers = {
    headers: {
      Accept: "text/plain",
      "Content-Type": "application/json",
    },
  };
  const handlePhotoChange = event => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImgp(imageUrl);
    if (file && file.size <= 3 * 1024 * 1024) {
      // Set formData.photo to the selected File object
      setFormData(prevFormData => ({
        ...prevFormData,
        photo: file,
      }));
    }
  };
  const handleChange = e => {
    const { name, value } = e.target;
    let parsedValue = value;
    if (name.endsWith("Id")) {
      parsedValue = parseInt(value);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: parsedValue,
    }));
  };

  const getuserbyId = () => {
    axios
      .get(`https://api.kursline.az/GetUserbyId/${auth.user.userInfo.id}`, {
        headers: {
          Authorization: `Bearer ${auth.user.accsessToken}`,
        },
      })
      .then(res => {
        setFormData(res.data);
      });
  };
  useEffect(() => {
    if (auth.roles === 3) {
      setUserProfile(
        <React.Fragment>
          <Link to="/UserProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/CompanyProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    }
    setToken(auth.user.accsessToken);
    axios.get("https://api.kursline.az/GetRegisterDatas").then(function (res) {
      setOption(res.data);
    });
    getuserbyId();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const formData1 = new FormData();
    formData1.append("companyAbout", formData.companyAbout);
    formData1.append("companyCategoryId", formData.companyCategoryId);
    formData1.append("companyName", formData.companyName);
    formData1.append("companyRegionId", formData.companyRegionId);
    formData1.append("photos", formData.photo);
    formData1.append("CompanyStatusId", formData.userStatusId);
    console.log(formData.photo);
    // Send the formData or perform other actions here
    axios
      .post(
        `https://api.kursline.az/CreateCompany/${auth.user.userInfo.id}`,
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          const tok = {
            refreshToken: localStorage.getItem("refreshToken"),
          };
          (async () => {
            try {
              axios
                .post(`https://api.kursline.az/refresh-token`, tok, {
                  headers: headers,
                })
                .then(function (res) {
                  if (res.data != null) {
                    const roles = res.data.userInfo.userStatus;
                    const user = {
                      accsessToken: res.data.accessToken.token,
                      refreshToken: res.data.refreshToken.token,
                      isEmailVerified: res.data.isEmailVerified,
                      userInfo: res.data.userInfo,
                    };
                    setAuth({ user, roles });
                    localStorage.clear();
                    localStorage.setItem(
                      "refreshToken",
                      res.data.refreshToken.token
                    );
                    // navigate("/", { replace: true });
                  }
                });
            } catch (error) {
              if (error.response.status === 400) {
                console.log("error");
              }
            }
          })();
          // setAuth();
          // navigate("/login", { replace: true });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.code != "SERVER_ERROR") {
          if (error.response.status == 400) {
            const errorDetails = error.response.data.details;
            setCompnameerror(errorDetails.CompanyName);
            setAbouterror(errorDetails.CompanyAbout);
            setRegionerror(errorDetails.CompanyRegionId);
            setCaterror(errorDetails.CompanyCategoryId);
            setStatuserror(errorDetails.CompanyStatusId);
          }
        }
      });
  };

  return (
    <div className="bs-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <div className="user-navbar">
        {userProfile}
        <Link to="/UserUpdatetoBiznes" className="user-navbar-announcements">
          Mənim elanlarım
        </Link>
      </div>

      <div className="businessProfile">
        <form onSubmit={handleSubmit}>
          <div className="frm-first">
            <div className="first-img">
              <input
                type="file"
                id="Adınız"
                name="photo"
                style={{ display: "none" }}
                onChange={handlePhotoChange}
                ref={photo}
              />
              <img
                // src={formData.photo instanceof File ? imgp : formData.photo}
                src={
                  formData?.photo instanceof File
                    ? imgp
                    : formData?.photo
                    ? formData?.photo
                    : userimg
                }
                alt="kursline"
                onClick={() => photo.current.click()}
              />
            </div>
            <div className="first-info">
              <div className="fr-items">
                <label htmlFor="Adınız">
                  {formData.fullName ? (
                    "Ad və soyad"
                  ) : nameerror ? (
                    <span className="error">*{nameerror}</span>
                  ) : (
                    "Ad və soyad"
                  )}
                </label>
                <input
                  style={
                    formData.fullName
                      ? {}
                      : nameerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  id="Adınız"
                  name="fullName"
                  disabled
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {/* {
                       formData.fullName ?  <></>: nameerror ? <span className='error'>*{nameerror}</span>:<></>
                      } */}
              </div>
              <div className="fr-items">
                <label htmlFor="Şirkət adı">
                  {formData.companyName ? (
                    "Şirkət adı"
                  ) : compnameerror ? (
                    <span className="error">*{compnameerror}</span>
                  ) : (
                    "Şirkət adı"
                  )}
                </label>
                <input
                  style={
                    formData.companyName
                      ? {}
                      : compnameerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  id="Adınız"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>

              <div className="fr-items">
                <label htmlFor="UserPhone">
                  {" "}
                  {formData.mobileNumber ? (
                    "Telefon"
                  ) : Phoneerror ? (
                    <span className="error">*{Phoneerror}</span>
                  ) : (
                    "Telefon"
                  )}
                </label>
                <input
                  style={
                    formData.mobileNumber
                      ? {}
                      : Phoneerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="tel"
                  id="UserPhone"
                  name="mobileNumber"
                  disabled
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="frm-second">
            <div className="second-selects">
              <select
                name="companyRegionId"
                style={
                  formData.companyRegionId
                    ? {}
                    : regionerror
                    ? { border: "1px solid red" }
                    : {}
                }
                id=""
                onChange={handleChange}
              >
                <option defaultValue="">Region seç</option>
                {option &&
                  option.regions.map(e => (
                    <option
                      key={e.regionId}
                      selected={e.regionId === formData.companyRegionId}
                      defaultValue={e.regionId}
                      value={e.regionId}
                    >
                      {e.regionName}
                    </option>
                  ))}
              </select>
              <select
                name="companyCategoryId"
                id=""
                style={
                  formData.companyCategoryId
                    ? {}
                    : caterror
                    ? { border: "1px solid red" }
                    : {}
                }
                onChange={handleChange}
              >
                <option defaultValue="">Kateqoriya seç</option>
                {option &&
                  option.categories.map(e => (
                    <option
                      key={e.categoryId}
                      selected={e.categoryId === formData.companyCategoryId}
                      defaultValue={parseInt(e.categoryId)}
                      value={e.categoryId}
                    >
                      {e.categoryName}
                    </option>
                  ))}
              </select>
              <select
                name="userStatusId"
                className="rejimSelect"
                onChange={handleChange}
                style={
                  formData.userStatusId
                    ? {}
                    : statuserror
                    ? { border: "1px solid red" }
                    : {}
                }
              >
                <option defaultValue="">Status seç</option>
                {option &&
                  option.statuses.map(e => (
                    <option
                      key={e.statusId}
                      selected={e.statusId === formData.userStatusId}
                      defaultValue={parseInt(e.statusId)}
                      value={e.statusId}
                    >
                      {e.statusName}
                    </option>
                  ))}
              </select>
            </div>
            <div className="text-arr">
              <label htmlFor="UserPhone">
                {formData.companyAbout ? (
                  "Şirkət haqqında"
                ) : Abouterror ? (
                  <span className="error">*{Abouterror}</span>
                ) : (
                  "Şirkət haqqında"
                )}
              </label>
              <textarea
                style={
                  formData.companyAbout
                    ? {}
                    : Abouterror
                    ? { border: "1px solid red" }
                    : {}
                }
                name="companyAbout"
                value={formData.companyAbout}
                onChange={handleChange}
              ></textarea>
              <button type="submit">Yaddaşda saxla</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateUsertoBisnes;
