import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import BounceLoader from "react-spinners/BounceLoader";
function Layout() {
  const [loading, setLoading] = useState(true);
  const [color] = useState("green");
  useEffect(() => {
    // setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        alignitems: "center",
      }}
    >
      <BounceLoader
        color={color}
        loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div style={{ margin: "0", padding: "0", height: "100vh" }}>
      <Navbar></Navbar>

      <div style={{ minHeight: "73%", background: "white" }}>
        <Outlet />
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Layout;
