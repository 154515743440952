import React, { useEffect, useState } from "react";
import "./category.css";
import useAuth from "./../../Hooks/useAuth";
import axios from "axios";
import Swal from "sweetalert2";
function Categories() {
  const { auth } = useAuth();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategories] = useState();
  const [editCategory, setEditCat] = useState("");
  const [editCatName, setEditCatName] = useState("");
  const [editError, setEditError] = useState("");
  const [newcat, setNewcat] = useState("");
  const [newcaterror, setNewcaterror] = useState("");
  const [editaltCategory, seteditaltCat] = useState("");
  const [editAltCatName, seteditAltCatName] = useState("");
  const [editAltError, seteditAltError] = useState("");
  const [AltCategoryId, setnewAltCategoryId] = useState("");
  const [newAltCatName, setnewAltCatName] = useState("");
  const [newAltError, setnewAltError] = useState("");
  const [altcategoryId, setaltcategoryId] = useState("");
  const [deleteCategory, setDeleteCategory] = useState("");
  const [delaltsubCatId, setDeletesubcatId] = useState("");
  const [deletealtCategoryId, setDeleteAltCategory] = useState("");

  const DeleteSubCategory = e => {
    e.preventDefault();

    axios
      .post(
        `https://api.kursline.az/Admin/DeleteSubCategory/${auth.user.userInfo.id}/${delaltsubCatId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          Swal.fire(
            "Deleted!",
            "Your SubCategory has been deleted.",
            "success"
          );
        }
        getcatss();
      })
      .catch(err => {
        console.log(err);
        Swal.fire("Deleted!", ``, "error");
      });
  };

  const getcatss = () => {
    axios
      .get(
        `https://api.kursline.az/Admin/GetCategories/${auth?.user?.userInfo?.id}`,
        "",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth?.user?.accsessToken}`,
          },
        }
      )
      .then(res => {
        setCategory(
          res.data.map(categories => ({
            label: categories.categoryName,
            value: categories.categoryId,
          }))
        );
        const subCategories = res.data.flatMap(
          category => category.subCategories
        );
        setSubCategories(subCategories);
      });
  };
  useEffect(() => {
    getcatss();
  }, []);

  const handleAddNewCategory = e => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("CategoryName", newcat);
    setNewcaterror("");
    axios
      .post(
        `https://api.kursline.az/Admin/AddCategory/${auth.user.userInfo.id}`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          Swal.fire("Added!", "Your Category has been added.", "success");
        }
        getcatss();

        setNewcat("");
      })
      .catch(err => {
        console.log(err);
        setNewcat("");
        if (err.response.status === 400) {
          setNewcaterror(err.response.data.details.CategoryName);
          Swal.fire(
            "Confilict!",
            `${err.response.data.details.CategoryName}`,
            "error"
          );
        }
      });
  };
  const handleEditCategory = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("CategoryName", editCatName);
    axios
      .post(
        `https://api.kursline.az/Admin/EditCategory/${auth.user.userInfo.id}/${editCategory}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          Swal.fire("Updated!", "Your Category has been updated.", "success");
        }
        getcatss();
      })
      .catch(err => {
        Swal.fire("Conflict!", ``, "error");
        console.log(err);
      });
  };
  const handleEditAltCategory = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("SubCategoryName", editAltCatName);
    axios
      .post(
        `https://api.kursline.az/Admin/EditSubCategory/${auth.user.userInfo.id}/${AltCategoryId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          Swal.fire(
            "Updated!",
            "Your SubCategory has been updated.",
            "success"
          );
        }
        getcatss();
      })
      .catch(err => {
        Swal.fire("Conflict!", ``, "error");
        console.log(err);
      });
  };
  const handleNewAltCategory = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("SubCategoryName", newAltCatName);
    setnewAltError("");
    axios
      .post(
        `https://api.kursline.az/Admin/AddSubCategory/${auth.user.userInfo.id}/${editaltCategory}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          Swal.fire("Added!", "Your SubCategory has been Added.", "success");
        }
        setnewAltCatName("");
        getcatss();
      })
      .catch(err => {
        setnewAltCatName("");
        setnewAltError(err.response.data.details.SubCategoryName);
        Swal.fire(
          "Added!",
          `${err.response.data.details.SubCategoryName}`,
          "success"
        );
        console.log(err.response.data.details.SubCategoryName);
      });
  };

  const handleDeleteCategory = e => {
    e.preventDefault();
    axios
      .post(
        `https://api.kursline.az/Admin/DeleteCategory/${auth.user.userInfo.id}/${deleteCategory}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your Category has been deleted.", "success");
        }
        getcatss();
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div className="category-page">
      <div className="cont-cat">
        <div className="cat-form">
          <div className="cat-header">
            <h1>Kateqoriyalar</h1>
          </div>
          <div className="cat-body">
            <form
              className="cat-new-items container"
              onSubmit={handleDeleteCategory}
            >
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={deleteCategory}
                  onChange={e => setDeleteCategory(e.target.value)}
                >
                  <option value="">Kateqoriya seçin</option>
                  {category &&
                    category.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="new-btn">
                <button className="btn btn-success">Sil</button>
              </div>
            </form>
            <form
              className="cat-new-items container"
              onSubmit={handleAddNewCategory}
            >
              <div className="cat-half new-half">
                <label htmlFor="editCatName">
                  {newcat ? (
                    "kategoriya adı"
                  ) : newcaterror ? (
                    <span className="error">*{newcaterror}</span>
                  ) : (
                    "kategoriya adı"
                  )}
                </label>
                <input
                  style={
                    newcat ? {} : newcaterror ? { border: "1px solid red" } : {}
                  }
                  type="text"
                  className="announinput"
                  value={newcat}
                  id="editCatName"
                  onChange={e => setNewcat(e.target.value)}
                />
              </div>
              <div className="new-btn">
                <button className="btn btn-success">Əlavə et</button>
              </div>
            </form>
            <form className="cat-items container" onSubmit={handleEditCategory}>
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={editCategory}
                  onChange={e => setEditCat(e.target.value)}
                >
                  <option value="">Kateqoriya seçin</option>
                  {category &&
                    category.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="cat-half">
                <label htmlFor="editCatName">
                  {editCatName ? (
                    "kategoriya adı"
                  ) : editError ? (
                    <span className="error">*{editError}</span>
                  ) : (
                    "kategoriya adı"
                  )}
                </label>
                <input
                  style={
                    editCatName
                      ? {}
                      : editError
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="announinput"
                  value={editCatName}
                  id="editCatName"
                  onChange={e => setEditCatName(e.target.value)}
                />
              </div>
              <div>
                <button className="btn btn-success">Dəyişiklik et</button>
              </div>
            </form>
          </div>
        </div>
        <div className="cat-form">
          <div className="cat-header">
            <h1>Alt-Kateqoriyalar</h1>
          </div>
          <div className="cat-body">
            <form
              className="cat-items container"
              onSubmit={handleNewAltCategory}
            >
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={editaltCategory}
                  onChange={e => seteditaltCat(e.target.value)}
                >
                  <option value="">Kateqoriya seçin</option>
                  {category &&
                    category.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="cat-half">
                <label htmlFor="editCatName">
                  {newAltCatName ? (
                    "Alt-kategoriya adı"
                  ) : newAltError ? (
                    <span className="error">*{newAltError}</span>
                  ) : (
                    "Alt-kategoriya adı"
                  )}
                </label>
                <input
                  style={
                    newAltCatName
                      ? {}
                      : newAltError
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="announinput"
                  value={newAltCatName}
                  id="editCatName"
                  onChange={e => setnewAltCatName(e.target.value)}
                />
              </div>
              <div>
                <button className="btn btn-success">Əlavə et</button>
              </div>
            </form>
            <form
              className="cat-items container"
              onSubmit={handleEditAltCategory}
            >
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={altcategoryId}
                  onChange={e => setaltcategoryId(e.target.value)}
                >
                  <option value="">Kateqoriya seçin</option>
                  {category &&
                    category.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={AltCategoryId}
                  onChange={e => setnewAltCategoryId(e.target.value)}
                >
                  <option value="">Alt-kateqoriya seçin</option>
                  {altcategoryId &&
                    subCategory &&
                    subCategory
                      .filter(
                        x => x.subCategoryCategoryId === parseInt(altcategoryId)
                      )
                      .map(e => (
                        <option key={e.subCategoryId} value={e.subCategoryId}>
                          {e.subCategoryName}
                        </option>
                      ))}
                  {/* {subCategory &&
                    subCategory.map(e => (
                      <option key={e.subCategoryId} value={e.subCategoryId}>
                        {e.subCategoryName}
                      </option>
                    ))} */}
                </select>
              </div>
              <div className="cat-half">
                <label htmlFor="editCatName">
                  {editAltCatName ? (
                    "Alt-kategoriya adı"
                  ) : editAltError ? (
                    <span className="error">*{editAltError}</span>
                  ) : (
                    "Alt-kategoriya adı"
                  )}
                </label>
                <input
                  style={
                    editAltCatName
                      ? {}
                      : editAltError
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="text"
                  className="announinput"
                  value={editAltCatName}
                  id="editCatName"
                  onChange={e => seteditAltCatName(e.target.value)}
                />
              </div>
              <div>
                <button className="btn btn-success">Dəyişiklik et</button>
              </div>
            </form>
            <form className="cat-items container" onSubmit={DeleteSubCategory}>
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={deletealtCategoryId}
                  onChange={e => setDeleteAltCategory(e.target.value)}
                >
                  <option value="">Kateqoriya seçin</option>
                  {category &&
                    category.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="focused ">
                {" "}
                <select
                  className="form-select"
                  value={delaltsubCatId}
                  onChange={e => setDeletesubcatId(e.target.value)}
                >
                  <option value="">Alt-kateqoriya seçin</option>
                  {deletealtCategoryId &&
                    subCategory &&
                    subCategory
                      .filter(
                        x =>
                          x.subCategoryCategoryId ===
                          parseInt(deletealtCategoryId)
                      )
                      .map(e => (
                        <option key={e.subCategoryId} value={e.subCategoryId}>
                          {e.subCategoryName}
                        </option>
                      ))}
                  {/* {subCategory &&
                    subCategory.map(e => (
                      <option key={e.subCategoryId} value={e.subCategoryId}>
                        {e.subCategoryName}
                      </option>
                    ))} */}
                </select>
              </div>
              <div>
                <button className="btn btn-success">Sil</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
