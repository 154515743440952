import React, { useState, useEffect } from "react";
import "../Vips/vip.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../Hooks/useAuth";
import ClipLoader from "react-spinners/ClipLoader";
import { Helmet } from 'react-helmet';


function Irelicek() {
  const formRef = React.createRef();
  const { id } = useParams();
  const { auth } = useAuth();
  const [info, setInfo] = useState();
  const [vipid, setvipid] = useState();
  const [price, setPrice] = useState();
  const [permition, setPerm] = useState(false);
  const [activ, setActiv] = useState(true);

  const testiq = () => {
    if (permition === true) {
      setPerm(false);
    } else {
      setPerm(true);
    }
  };
  const testhandle = e => {
    setvipid(e.target.getAttribute("irelicekid"));
    setPrice(e.target.getAttribute("price"));
    localStorage.setItem("ireliCekId", e.target.getAttribute("irelicekid"));
  };
  const sendhandle = () => {
    setActiv(false);
    var bod = JSON.stringify({
      announcementId: id,
      vipId: 0,
      ireliCekId: vipid,
      orderKey: "",
      sessionId: "",
    });
    axios
      .post(
        `https://api.kursline.az/PostPayment/${auth.user.userInfo.id}`,
        bod,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          setActiv(true);
          localStorage.setItem("OrderId", res.data.orderId);
          window.open(res.data.link, "_blank");
        }
        setActiv(true);
      })
      .catch(err => {
        console.log(err);
        setActiv(true);
      });
  };
  useEffect(() => {
    localStorage.setItem("announcmentId", id);
    axios
      .get(
        `https://api.kursline.az/GetIreliCekInfo/${auth?.user?.userInfo?.id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(res => {
        setInfo(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="vip-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta name="description" content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili" />
        <meta name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt" content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."/>
        <meta name="keywords" content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"/>
        <meta name="robots" content="index, follow"/>
        <meta name="author" content="Kursline.az komandası"/>
        <meta name="og:title" property="og:title" content="Kursline.az - Kurs Elanları, tədris fürsətləri"/>
        <meta name="og:description" property="og:description" content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."/>
        <meta name="og:image" property="og:image" content="https://www.kursline.az/kursline (1).png"/>
        <meta name="og:url" property="og:url" content="https://www.kursline.az"/>
        <meta name="og:site_name" property="og:site_name" content="Kursline.az"/>
      </Helmet>
      <div className="vip-infotesdiq">
        <div className="vip-notice">
          <p>
            Elanınız ana səhifədə və axtarış nəticələrindəki VİP bölməsində
            təsadüfi qaydada görünəcək
          </p>
        </div>
        <div className="vip-info">
          <div className="vip-news">
            {info?.elanInfo ? <p>{info.elanInfo}</p> : <p></p>}
          </div>
          <div className="vip-parametrs">
            <h5>Xidmətin müddəti</h5>
            <ul>
              {info?.ireliCekInfo &&
                info.ireliCekInfo.map(e => (
                  <li key={e.irelicekId}>
                    <label
                      irelicekid={e.irelicekId}
                      price={e.irelicekCost}
                      className={
                        vipid
                          ? vipid == e.irelicekId
                            ? "selected select"
                            : "select"
                          : "select"
                      }
                      htmlFor={`vipPrice-${e.irelicekId}`}
                      onClick={event => testhandle(event)}
                    ></label>
                    <h3>
                      {e.irelicekDate}gun/{e.irelicekCost}Azn
                    </h3>
                  </li>
                ))}
            </ul>
          </div>
          <div className="vip-method">
            <h5>Ödəniş üsulu</h5>
            <div>
              <span className="select selected"></span>
              <h3>Bank kartı</h3>
            </div>
          </div>
        </div>
        <div className="vip-submit">
          <button
            className="vip-btn"
            onClick={sendhandle}
            disabled={!permition}
          >
            {!activ ? (
              <ClipLoader
                color="white"
                cssOverride={{}}
                loading={!activ}
                size={18}
                speedMultiplier={1}
              />
            ) : (
              "Ödə"
            )}
          </button>
          <p>
            <span
              className={permition ? "select selected" : "select"}
              onClick={testiq}
            ></span>
            “Ödə” düyməsini sıxmaqla siz kursline.az-ın istifadəçi sözləşməsini
            qəbul etmiş olursunuz.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Irelicek;
