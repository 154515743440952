import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import MainContext from "../../context/MainContext";
import srimg from "../../images/framequiz.png";
import axios from "axios";
import "../Admin/Admin.css";
import useAuth from "../../Hooks/useAuth";
function Admin() {
  const { auth } = useAuth();
  const [announcements, setAnnouncements] = useState([]);
  const [activeItem, setActiveItem] = useState(1);

  function truncateText(text, maxLength) {
    if (text != null) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.substring(0, maxLength) + "...";
      }
    }
  }

  const handleItemClick = index => {
    if (activeItem === index) {
      // If the clicked item is already active, deactivate it
      setActiveItem(null);
    } else {
      // If a different item is active, deactivate it and activate the clicked item
      setActiveItem(index);
    }
  };
  useEffect(() => {
    axios
      .get(
        `https://api.kursline.az/Admin/getAnnouncments/${auth.user.userInfo.id}?IsActive=${activeItem}`,
        { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
      )
      .then(function (res) {
        if (res.data != null) {
          setAnnouncements(res.data);
        } else {
          setAnnouncements("");
        }
      });
  }, [activeItem]);

  return (
    <div className="ad-elan-main">
      <div className="ad-elan-main-nav">
        <ul>
          <li
            onClick={() => handleItemClick(1)}
            className={activeItem === 1 ? "isactive" : ""}
          >
            Aktiv
          </li>
          <li
            onClick={() => handleItemClick(3)}
            className={activeItem === 3 ? "isactive" : ""}
          >
            Gözləmədə
          </li>
          <li
            onClick={() => handleItemClick(2)}
            className={activeItem === 2 ? "isactive" : ""}
          >
            Deaktiv
          </li>
          <li
            onClick={() => handleItemClick(5)}
            className={activeItem === 5 ? "isactive" : ""}
          >
            Silinmiş
          </li>
          <li
            onClick={() => handleItemClick(4)}
            className={activeItem === 4 ? "isactive" : ""}
          >
            Block
          </li>
        </ul>
      </div>
      <div className="ad-elanlar">
        <div className="ad-user-cards ">
          {announcements.length > 0 ? (
            announcements
              .slice()
              .reverse()
              .map(e => (
                <div className="card card2" key={e.id}>
                  <div className="content">
                    <div className="buttons">
                      <div className="button_1">
                        <button className="but11">{e.isOnline}</button>
                        {/* <button className="but12">{e.isRejim}</button> */}
                      </div>
                      <div className="icons">
                        {e.IsVIP ? (
                          <i className="fa-solid fa-gem"></i>
                        ) : (
                          <i className="fa-regular fa-gem"></i>
                        )}
                      </div>
                    </div>
                    <div className="image">
                      <NavLink to={`/Admin/Elan/${e.id}`}>
                        <img src={e.photos[0]?.url} alt="elan" />
                      </NavLink>
                    </div>
                    <div className="desc">
                      <h3>{truncateText(e.announcementName, 10)}</h3>
                      <h5>{truncateText(e.companyName, 10)}</h5>
                      <p>{truncateText(e.announcementDesc, 10)}</p>
                    </div>
                    <Link className="postDesc" to={`/Admin/Elan/${e.id}`}>
                      Details
                    </Link>
                  </div>
                </div>
              ))
          ) : (
            <div className="user-null">
              <div className="user-srimg">
                <h3></h3>
                <img src={srimg} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Admin;
