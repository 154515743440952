import React, { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import axios from "axios";
import Card from "../../components/Card";
import { Helmet } from "react-helmet-async";

function Favorites() {
  const { auth } = useAuth();
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`https://api.kursline.az/GetFavorite/${auth.user.userInfo.id}`, {
        headers: {
          Authorization: `Bearer ${auth.user.accsessToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAnnouncements(res.data);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <hr />{" "}
      <div className="cards">
        {isLoading ? (
          <h1></h1>
        ) : announcements.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "70%",
              margin: "auto",
            }}
          >
            <h1>Bəyənilən elanınız yoxdur.</h1>
          </div>
        ) : (
          announcements.map((announcement) => (
            <Card key={announcement.id} announcement={announcement} />
          ))
        )}
      </div>
    </div>
  );
}

export default Favorites;
