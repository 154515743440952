import { NavLink } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import "./Card.css";
export default function Card({ announcement }) {
  const { auth } = useAuth();
  const [isfavor, setisfavor] = useState(announcement.isFavorite);
  const AddFavorite = e => {
    console.log(auth);
    if (auth?.user?.userInfo?.id !== undefined) {
      axios
        .post(
          `https://api.kursline.az/PostFavorite/${auth?.user?.userInfo?.id}/${e}`,
          "",
          {
            headers: {
              Authorization: `Bearer ${auth?.user?.accsessToken}`,
            },
          }
        )
        .then(res => {
          // console.log(res.data.isSuccess);
          if (res.status === 200) {
            setisfavor(res.data.isSuccess);
          }
        })
        .catch(err => {
          // console.log(err);
        });
    } else {
      Swal.fire(
        "Uğursuz!",
        "Bunun üçün qeydiyatdan keçməlisiz və ya profilə daxil olmalısınız.",
        "error"
      );
    }
    // axios
    //   .post(
    //     `https://kursline.az/PostFavorite/${auth.user.userInfo.id}/${e}`,
    //     "",
    //     {
    //       headers: {
    //         Authorization: `Bearer ${auth.user.accsessToken}`,
    //       },
    //     }
    //   )
    //   .then(res => {
    //     // console.log(res.data.isSuccess);
    //     if (res.status === 200) {
    //       setisfavor(res.data.isSuccess);
    //     }
    //   })
    //   .catch(err => {
    //     // console.log(err);
    //   });
  };

  function truncateText(text, maxLength) {
    if (text != null) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.substring(0, maxLength) + "...";
      }
    }
  }
  return (
    <>
      <div className="card" key={announcement.announcementId}>
        <div className="content">
          <div className="buttons">
            <div className="btnfvrt" style={{ width: "100%" }}>
              {" "}
              {isfavor ? (
                <i
                  className="fa-solid fa-heart"
                  style={{ color: "#ed0707", cursor: "pointer" }}
                  elanid={announcement.id}
                  onClick={event =>
                    AddFavorite(event.target.getAttribute("elanid"))
                  }
                ></i>
              ) : (
                <i
                  className="fa-regular fa-heart"
                  style={{ cursor: "pointer" }}
                  elanid={announcement.id}
                  onClick={event =>
                    AddFavorite(event.target.getAttribute("elanid"))
                  }
                ></i>
              )}
            </div>
            <div className="buttonss" style={{ width: "100%" }}>
              <div className="button_1">
                <button className="but11">
                  {announcement.isOnline.substring(0, 9)}
                </button>
                <NavLink to={`/CourseProfile/${announcement.companyId}`}>
                  <button className="but12">{announcement.isStatus}</button>
                </NavLink>
              </div>
              <div className="icons">
                {announcement.isVIP === true ? (
                  <i className="fa-solid fa-gem"></i>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="image">
            <NavLink to={`/announcementdetails/${announcement.id}`}>
              <img
                src={announcement.photos[0]?.url}
                alt={`${announcement.companyName}, ${announcement.isStatus}, kursline, Kurslar, Kurs Axtar, Online Kurs, IT, Developer,KURSLAR və Təlimlər, Online kurslar , Bakida hazirliq kurslari qiymeti`}
              />
            </NavLink>
          </div>
          <div className="desc">
            <h3>{truncateText(announcement.announcementName, 16)}</h3>
            <h5>
              {" "}
              <NavLink
                to={`/CourseProfile/${announcement.companyId}`}
                style={{ color: "turquoise" }}
              >
                {truncateText(announcement.companyName, 12)}{" "}
              </NavLink>
            </h5>
            <p>{truncateText(announcement.announcementDesc, 15)} </p>
          </div>
          {/* <div style={{ width: "100%", padding: "0 10px" }}>
            <NavLink
              className="postDesc"
              style={{ width: "100% !important" }}
              to={`/announcementdetails/${announcement.id}`}
            >
              <i class="fa-solid fa-right-to-bracket"></i>
            </NavLink>
          </div> */}
        </div>
      </div>
    </>
  );
}
