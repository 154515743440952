import React from "react";
import { useState, useEffect } from "react";
import useAuth from "../../Hooks/useAuth";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function MyAnnouncments() {
  const [userProfile, setUserProfile] = useState("");
  const { auth } = useAuth();
  useEffect(() => {
    if (auth.roles === 3) {
      setUserProfile(
        <React.Fragment>
          <Link to="/UserProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else if (auth.roles === 4) {
      setUserProfile(
        <React.Fragment>
          <Link to="/admin/dashboard" className="user-navbar-profile">
            Admin panel
          </Link>
          <Link
            to="/UserProfile"
            className="user-navbar-profile"
            style={{ marginLeft: "20px" }}
          >
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/CompanyProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    }
  }, []);

  return (
    <div className="bs-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <div className="user-navbar">
        {userProfile}
        <Link to="#" className="user-navbar-announcements">
          Mənim elanlarım
        </Link>
      </div>
      <div className="user-elan-main">
        <div className="user-elanlar">
          <div className="reg-tobiznes">
            <p>
              Dəyərli istifadəçi, hesabınızı asanlıqla biznes hesabına keçirə
              bilərsiniz.
            </p>
          </div>
          <div className="reg-tobiznes-link">
            <div>
              <h5>Biznes hesabınız yoxdur? </h5>
            </div>
            <div>
              <Link to={"/UserUpdatetoBiznes"} className="bz-link">
                <span className="btn-succes">Biznes hesab yaradın</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAnnouncments;
