// PrivateRoute.js
import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import useLocalStorage from "../Hooks/useLocalStorage";
import useAuth from "../Hooks/useAuth";
const PrivateRoute = ({ element, roles }) => {
  const { auth, setAuth } = useAuth();
  const [local, setLocal] = useLocalStorage("Auth", null);

  useEffect(() => {
    if (local !== null) {
      setAuth(local);
    }
  }, []);
  return local !== null ? (
    roles.find(x => x === local?.roles) ? (
      element
    ) : (
      <Navigate to="/auth/404" />
    )
  ) : (
    <Navigate to="/auth/login" />
  );
};

export default PrivateRoute;
