import axios from "axios";
import React, { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
function Commentler() {
  const { auth } = useAuth();
  const [comment, setComment] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api.kursline.az/Admin/GetComments/${auth.user.userInfo.id}`,
        {
          headers: { Authorization: `Bearer ${auth.user.accsessToken}` },
        }
      )
      .then(function (res) {
        setComment(res.data);
      });
  }, []);

  const handleAktivate = e => {
    const mesid = e.target.getAttribute("cmid");
    axios
      .post(
        `https://api.kursline.az/Admin/PostCommentAktiv/${auth?.user?.userInfo?.id}/${mesid}`,
        "",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth?.user?.accsessToken}`,
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          const updatedComments = comment.map(e => {
            if (e.commentId == mesid) {
              return {
                ...e,
                commentStatus: res.data.isSuccess, // Update the commentStatus property
              };
            }
            return e; // Keep other comment objects unchanged
          });
          setComment(updatedComments);
          //   Swal.fire("Deleted!", "Your file has been deleted.", "success");
          Swal.fire(
            `${res.data.isSuccess ? "Activated" : "Deactivated"}`,
            `${res.data?.isSuccess}`,
            "success"
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleDelete = e => {
    const mesid = e.target.getAttribute("msid");
    Swal.fire({
      title: `Are you sure? ${mesid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/DeleteComment/${auth?.user?.userInfo?.id}/${mesid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.status === 200) {
              const a = comment.filter(x => x.commentId != mesid);
              setComment(a);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Not Deleted!", `${res.response.data?.title}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  return (
    <div className="ad-mess-main" style={{ position: "relative" }}>
      <div>
        <table
          className="table table-dark table-hover"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th scope="col">Yazan</th>
              <th scope="col">Comment</th>
              <th scope="col">Elan</th>
              <th scope="col">Aktiv/Deaktiv/Sil</th>
            </tr>
          </thead>
          <tbody>
            {comment &&
              comment
                .slice()
                .reverse()
                .map(e => (
                  <tr
                    key={e.commentId}
                    className={e.CommentStatus ? "" : "table-active"}
                  >
                    <td>
                      <Link to={`/Admin/Elan/${e.announcmentId}`}>
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "100%",
                          }}
                          src={e.photo}
                        />
                      </Link>
                    </td>
                    <td>{e.commentContent}</td>
                    <td>
                      <Link to={`/Admin/Elan/${e.announcmentId}`}>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={e.announcmentPhoto}
                        />
                      </Link>
                    </td>
                    <td>
                      {e.commentStatus ? (
                        <button
                          className="btn btn-danger"
                          onClick={handleAktivate}
                          cmid={e.commentId}
                        >
                          Deaktiv et
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={handleAktivate}
                          cmid={e.commentId}
                        >
                          Aktiv et
                        </button>
                      )}
                      {/* <button className="btn btn-success">Aktiv et</button> */}
                      <i
                        className="fa-solid fa-trash-can"
                        msid={e.commentId}
                        onClick={handleDelete}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          marginLeft: "21px",
                        }}
                      ></i>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {/* <div
        className={mdal ? "modal2 aktive-modal" : "modal2"}
        // style={mdal ? { display: "block" } : { display: "none" }}
      >
        <i
          className="fa-regular fa-rectangle-xmark close-mdl"
          onClick={openModal}
        ></i>
        <div className="message-head">
          <h2>Mesajın mətni</h2>
        </div>
        <div className="message-body">
          {mess &&
            mess.map(e => (
              <div>
                <div>
                  {" "}
                  <span style={{ color: "black" }}>
                    Əlaqə nömrəsi : {e.messagePhone}
                  </span>
                </div>
                <div className="mesaj-metni">
                  <span>{e.messageText}</span>
                </div>
              </div>
            ))}
        </div>
      </div> */}
    </div>
  );
}

export default Commentler;
