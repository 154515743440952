import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "./Loadable";
import PrivateRoute from "./PrivateRoute";
/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../components/layout/Layout")));
const Page404 = Loadable(lazy(() => import("../components/404")));
const BlankLayout = Loadable(
  lazy(() => import("../components/layout/BlankLayout"))
);
const AdminLayout = Loadable(
  lazy(() => import("../components/layout/Layout2"))
);
//chat
// authentication
const Login = Loadable(lazy(() => import("../pages/Login/login")));
const Home = Loadable(lazy(() => import("../pages/Home/Home")));
const AddAnnouncement = Loadable(
  lazy(() => import("../pages/AnnounCements/AddAnnouncement"))
);
const PaymentSucces = Loadable(
  lazy(() => import("../pages/Vips/paymentSucces"))
);
const PaymentError = Loadable(lazy(() => import("../pages/Vips/paymentError")));
const Contact = Loadable(lazy(() => import("../components/Contact")));
const CommanView = Loadable(lazy(() => import("../components/CommanView")));
const AnnouncementDetails = Loadable(
  lazy(() => import("../pages/AnnounCements/AnnouncementDetails"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("../components/PrivacyPolicy"))
);
const Post = Loadable(lazy(() => import("../pages/Blog/Post")));
const UserRole = Loadable(lazy(() => import("../components/Roles/UserRole")));
const About = Loadable(lazy(() => import("../components/Roles/About")));
const Roles = Loadable(lazy(() => import("../components/Roles/Roles")));
const Blog = Loadable(lazy(() => import("../pages/Blog/Post")));
const CourseProfile = Loadable(
  lazy(() => import("../pages/Course/CourseProfile"))
);
//user
const UserProfile = Loadable(lazy(() => import("../pages/User/UserProfile")));
const UpdateUsertoBisnes = Loadable(
  lazy(() => import("../pages/User/UpdateUsertoBisnes"))
);
const Favorites = Loadable(lazy(() => import("../pages/Favorites/Favorites")));
const NewAnnouncement = Loadable(
  lazy(() => import("../pages/AnnounCements/NewAnnouncement"))
);
const UserAnnouncements = Loadable(
  lazy(() => import("../pages/UserAnnouncements/UserAnnouncements"))
);
const BusinessProfile = Loadable(
  lazy(() => import("../pages/Business/BusinessProfile"))
);
const Vip = Loadable(lazy(() => import("../pages/Vips/vip")));
const Irelicek = Loadable(lazy(() => import("../pages/Irelicek/irelicek")));
const EditAnnouncement = Loadable(
  lazy(() => import("../pages/AnnounCements/EditAnnouncement"))
);
const DetailElan = Loadable(lazy(() => import("../pages/Admin/DetailElan")));
const Companies = Loadable(lazy(() => import("../pages/Admin/Companies")));
const AdminMessage = Loadable(
  lazy(() => import("../pages/Admin/AdminMessage"))
);
const Reklamlar = Loadable(lazy(() => import("../pages/Admin/Reklamlar")));
const Orders = Loadable(lazy(() => import("../pages/Admin/Orders")));
const Commentler = Loadable(lazy(() => import("../pages/Admin/Commentler")));
const Categories = Loadable(lazy(() => import("../pages/Admin/Categories")));
const Admin = Loadable(lazy(() => import("../pages/Admin/Admin")));

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/home" /> },
      { path: "/home", exact: true, element: <Home /> },
      { path: "/Elan-əlavə-et", exact: true, element: <AddAnnouncement /> },
      { path: "/paymentSucces/:id", exact: true, element: <PaymentSucces /> },
      { path: "/paymentError", exact: true, element: <PaymentError /> },
      { path: "/contact", exact: true, element: <Contact /> },
      { path: "/HighLights/:id", exact: true, element: <CommanView /> },
      {
        path: "/announcementdetails/:id",
        exact: true,
        element: <AnnouncementDetails />,
      },
      { path: "/PrivacyPolicy", exact: true, element: <PrivacyPolicy /> },
      { path: "/post/:id", exact: true, element: <Post /> },
      { path: "/UserRole", exact: true, element: <UserRole /> },
      { path: "/About", exact: true, element: <About /> },
      { path: "/Roles", exact: true, element: <Roles /> },
      { path: "/blog", exact: true, element: <Blog /> },
      { path: "/CourseProfile/:id", exact: true, element: <CourseProfile /> },
      {
        path: "/UserProfile",
        element: <PrivateRoute roles={[3, 4]} element={<UserProfile />} />,
      },
      {
        path: "/UserUpdatetoBiznes",
        element: (
          <PrivateRoute roles={[3, 4]} element={<UpdateUsertoBisnes />} />
        ),
      },
      {
        path: "/MyFavorites",
        element: <PrivateRoute roles={[1, 2, 3, 4]} element={<Favorites />} />,
      },
      {
        path: "/newannouncement",
        element: (
          <PrivateRoute roles={[1, 2, 3, 4]} element={<NewAnnouncement />} />
        ),
      },
      {
        path: "/MyAnnouncments",
        element: (
          <PrivateRoute roles={[1, 2, 3, 4]} element={<UserAnnouncements />} />
        ),
      },
      {
        path: "/PrivacyPolicy",
        element: (
          <PrivateRoute roles={[1, 2, 3, 4]} element={<PrivacyPolicy />} />
        ),
      },
      {
        path: "/CompanyProfile",
        element: (
          <PrivateRoute roles={[1, 2, 4]} element={<BusinessProfile />} />
        ),
      },
      {
        path: "/Vip/:id",
        element: <PrivateRoute roles={[1, 2, 4]} element={<Vip />} />,
      },
      {
        path: "/IreliCek/:id",
        element: <PrivateRoute roles={[1, 2, 4]} element={<Irelicek />} />,
      },
      {
        path: "/EditAnnouncement/:id",
        element: (
          <PrivateRoute roles={[1, 2, 4]} element={<EditAnnouncement />} />
        ),
      },

      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      {
        path: "/Admin/dashboard",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<Admin />} />,
      },
      {
        path: "/Admin/Elan/:id",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<DetailElan />} />,
      },
      {
        path: "/Admin/Sirketler",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<Companies />} />,
      },
      {
        path: "/Admin/Sikayetler",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<AdminMessage />} />,
      },
      {
        path: "/Admin/Reklamlar",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<Reklamlar />} />,
      },
      {
        path: "/Admin/Odəmələr",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<Orders />} />,
      },
      {
        path: "/Admin/Commentlər",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<Commentler />} />,
      },
      {
        path: "/Admin/Kateqoriyalar",
        exact: true,
        element: <PrivateRoute roles={[4]} element={<Categories />} />,
      },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/*", element: <Page404 /> },
      { path: "/login", element: <Login /> },
      { path: "*", element: <Navigate to="/*" /> },
    ],
  },
];

export default Router;
