import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../pages/AnnounCements/AnnouncementDetails.css";
import { useParams } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import userimg from "../../images/anonymous-avatar-icon-9.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
function DetailElan() {
  const { id } = useParams();
  const { auth } = useAuth();
  const [user, setUser] = useState();
  const [details, setDetail] = useState("");
  const [comments, setComments] = useState();
  const [redirect, setRedirect] = useState(false);
  const [mainimg, setMainimg] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/Admin/dashboard";

  const handleDeleteComment = e => {
    const cid = e.target.getAttribute("commentid");
    axios
      .post(
        `https://api.kursline.az/DeleteComment/${auth.user.userInfo.id}/${cid}`,
        "",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth?.user?.accsessToken}`,
          },
        }
      )
      .then(res => {
        if (res.data.isSuccess === true) {
          const filteredComments = comments.filter(
            x => x.commentId !== parseInt(cid)
          );
          setComments(filteredComments);
        }
      })
      .catch(err => {
        console.log(err.response.data);
      });
  };

  useEffect(() => {
    if (auth.user != null) {
      setUser(true);
    }
    axios
      .get(
        `https://api.kursline.az/Admin/GetAnnouncement/${auth?.user?.userInfo?.id}/${id}`,
        { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
      )
      .then(response => {
        console.log(response.data);
        setDetail(response.data);
        setComments(response.data.comments);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleAktiv = e => {
    const elanid = e.target.getAttribute("aktivid");
    Swal.fire({
      title: `Are you sure? ${elanid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Activate it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/Admin/AktivElan/${auth?.user?.userInfo?.id}/${elanid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.data.isSuccess === true) {
              Swal.fire(
                "Activated!",
                "Your file has been Activated.",
                "success"
              );
              setRedirect(true);
              navigate(from, { replace: true });
            } else {
              Swal.fire("Not Activated!", `${res.data?.details}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
            Swal.fire("Not Activated!", `Something wrong`, "error");
          });
      }
    });
  };
  const handleBlock = e => {
    const elanid = e.target.getAttribute("blockid");
    Swal.fire({
      title: `Are you sure? ${elanid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Block it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/Admin/BlockElan/${auth?.user?.userInfo?.id}/${elanid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.data.isSuccess === true) {
              Swal.fire("Blocked!", "Your file has been Blocked.", "success");
              setRedirect(true);
              navigate(from, { replace: true });
            } else {
              Swal.fire("Blocked!", `${res.data?.details}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  const handleDeactive = e => {
    const elanid = e.target.getAttribute("deactiveid");
    Swal.fire({
      title: `Are you sure? ${elanid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactivate it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/Admin/DeaktivElan/${auth?.user?.userInfo?.id}/${elanid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.data.isSuccess === true) {
              Swal.fire(
                "Deactivated!",
                "Your file has been Deactivated.",
                "success"
              );
              setRedirect(true);
              navigate(from, { replace: true });
            } else {
              Swal.fire("Deactivated!", `${res.data?.details}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };
  const handleDeleteAnnouncement = e => {
    const elanid = e.target.getAttribute("elanid");

    Swal.fire({
      title: `Are you sure? ${elanid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/Admin/DeleteElan/${auth?.user?.userInfo?.id}/${elanid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.data.isSuccess === true) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              setRedirect(true);
              navigate(from, { replace: true });
            } else {
              Swal.fire("Deleted!", `${res.data?.details}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  return (
    <div className="ad-elan-main">
      <div className="ad-det-main">
        <div className="add-detail">
          <h1>
            {details.announcementName} <span className="point">.</span>{" "}
            <span> {details.companyName}</span>
          </h1>
          <div className="main">
            <div className="left">
              <div className="small_img">
                {details.photos &&
                  details.photos.map((e, index) => (
                    <div key={index}>
                      <img
                        src={e?.url}
                        alt=""
                        onClick={e => setMainimg(e.target.src)}
                      />
                    </div>
                  ))}
              </div>
              <div className="big_img">
                <img
                  src={
                    mainimg
                      ? mainimg
                      : details.photos
                      ? details.photos[0]?.url
                      : ""
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="right">
              <div className="price">
                <button>{details.situationName}</button>
                <span className="blue_sp">
                  <i className="fa-solid fa-eye "></i>
                  {details.countView}
                </span>
                <button>{details.announcementPrice} AZN</button>
              </div>
              <div className="about">
                <ul>
                  <li>
                    <span className="blue_sp">Kateqoriya</span>{" "}
                    <span className="green_sp">
                      {details.announcementSubCategoryName}
                    </span>
                  </li>
                  <li>
                    <span className="blue_sp">Region</span>{" "}
                    <span className="green_sp">
                      {details.announcementRegionId}
                    </span>
                  </li>
                  <li>
                    <span className="blue_sp">Rejim</span>{" "}
                    <span className="green_sp">{details.isOnline}</span>
                  </li>
                  <li>
                    <span className="blue_sp">Müəllim</span>{" "}
                    <span className="green_sp">
                      {details.teacher &&
                        details.teacher.map((teacherName, index) => (
                          <p key={index}>{teacherName},</p>
                        ))}
                    </span>
                  </li>
                  <li>
                    <span className="blue_sp">Əlaqə</span>{" "}
                    <span className="green_sp">{details.phone}</span>
                  </li>
                </ul>
                {auth.user ? (
                  auth.roles === 4 ? (
                    <div className="isvip">
                      <button
                        className="crud bg_green"
                        aktivid={details.id}
                        onClick={handleAktiv}
                        disabled={details.situationId === 1}
                      >
                        Aktiv et
                      </button>
                      <button
                        className="crud bgred"
                        deactiveid={details.id}
                        onClick={handleDeactive}
                        disabled={details.situationId === 3}
                      >
                        Deaktiv et
                      </button>
                      <button
                        className="crud bgred"
                        elanid={details.id}
                        onClick={handleDeleteAnnouncement}
                      >
                        Elani sil
                      </button>
                      <button
                        className="crud bg_green"
                        blockid={details.id}
                        onClick={handleBlock}
                        disabled={details.situationId === 4}
                      >
                        Block et
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="desc">
            <p>{details.announcementDesc}</p>
          </div>
          <div className="comment-container">
            {comments &&
              comments.map(e => (
                <div className="comment" key={e.commentId}>
                  <div className="comment-photo">
                    {e.commentPhoto ? (
                      <img src={e.commentPhoto} alt="" />
                    ) : (
                      <img src={userimg} alt="" />
                    )}
                  </div>
                  <div className="comment-itself">
                    <div className="up-side">
                      <h5>{e.userFullName}</h5>
                    </div>
                    <p>{e.commentContent} </p>
                    {user === true ? (
                      4 === auth.roles ? (
                        <i
                          className="fa-solid fa-xmark"
                          commentid={e.commentId}
                          onClick={handleDeleteComment}
                        ></i>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            {/* <div className="new-comment">
          <div className="comment-form">
            <div className="comment-area">
              <form onSubmit={handleComment}>
                {user === true ? (
                  <input
                    className="add-comment"
                    placeholder="Şərh əlavə edin"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                ) : (
                  <></>
                )}
              </form>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailElan;
