import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Nav from "../admin/Navbar2";
import BounceLoader from "react-spinners/BounceLoader";
function Layout2() {
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);
  const [color] = useState("green");

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const override = {
    display: "block",
    color: "black ",
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        alignitems: "center",
      }}
    >
      <BounceLoader
        color={color}
        loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div
      style={{ margin: "0", padding: "0", height: "100vh", display: "flex" }}
    >
      <Nav mode={setDarkMode}></Nav>
      <div
        style={{
          width: "100%",
          backgroundColor: isDarkMode ? "black" : "white",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Layout2;
