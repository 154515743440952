import { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./AnnouncementDetails.css";
import { useParams, Link } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import userimg from "../../images/anonymous-avatar-icon-9.jpg";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import Card from "../../components/Card";
import { Helmet } from "react-helmet";

export default function AnnouncementDetails() {
  const { id } = useParams();
  const { auth } = useAuth();
  const [details, setDetail] = useState("");
  const [comment, setComment] = useState();
  const [comments, setComments] = useState();
  const [redirect, setRedirect] = useState(false);
  const [mainimg, setMainimg] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const from = "/MyAnnouncements";
  const controller = useRef(null);

  const handleDeleteComment = e => {
    const cid = e.target.getAttribute("commentid");
    axios
      .post(
        `https://api.kursline.az/DeleteComment/${auth.user.userInfo.id}/${cid}`,
        "",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${auth?.user?.accsessToken}`,
          },
        }
      )
      .then(res => {
        if (res.data.isSuccess === true) {
          const filteredComments = comments.filter(
            x => x.commentId !== parseInt(cid)
          );
          setComments(filteredComments);
        }
      })
      .catch(err => {
        console.log(err.response.data);
      });
  };

  useEffect(() => {
    controller.current = new AbortController();

    const fetchdata = async () => {
      axios
        .get(
          `https://api.kursline.az/GetAnnouncementById/${id}/${auth?.user?.userInfo?.id}`,
          { signal: controller.current.signal }
        )
        .then(response => {
          setDetail(response.data);
          setMainimg(response.data.photos[0]?.url);
          setComments(response.data.comments);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        })
        .catch(error => {
          console.log(error);
        });
    };
    fetchdata();
    return () => {
      controller.current.abort(); // Unmount sırasında iptal et
    };
  }, [id]);

  const handleComment = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("content", comment);
    await axios
      .post(
        `https://api.kursline.az/PostComment/${auth.user.userInfo.id}/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        if (res.data.isSuccess === true) {
          Swal.fire(
            "Uğurlu göndərildi! ",
            "Yoxlanıldıqdan sonra təsdiqlənəcək.",
            "success"
          );
          // const a = {
          //   commentId: parseInt(res.data.id),
          //   commentContent: comment,
          //   userFullName: info.user.userInfo.name,
          //   userId: parseInt(info.user.userInfo.id),
          //   commentPhoto: auth?.user.userInfo.photo,
          // };

          // setComments(e => [...e, a]);
          setComment("");
        }
      })
      .catch(err => {
        console.log(err.response.data);
      });
  };
  const handleDeleteAnnouncement = async e => {
    const elanid = e.target.getAttribute("elanid");
    Swal.fire({
      title: `Elanı silmək istədiyinizə əminsiniz? ${elanid}`,
      text: "Geri qaytarılmayacq!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Xeyr",
      confirmButtonText: "Bəli!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/DeleteAnnouncement/${auth?.user?.userInfo?.id}/${elanid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.data.isSuccess === true) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              setRedirect(true);
              navigate("/MyAnnouncments");
            } else {
              Swal.fire("Deleted!", `${res.data?.details}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };
  function formatDateTime(inputDateTime) {
    const date = new Date(inputDateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }
  function datedif(date) {
    const targetDate = new Date(date);
    const currentDate = new Date();
    // Target date ("2023-09-22T04:29:17.737")

    // Calculate the time difference in milliseconds
    const differenceInMilliseconds = currentDate - targetDate;

    // Convert milliseconds to days
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    // Get the time (HH:mm) from the target date
    const timeString = targetDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDateTime = formatDateTime(date);
    if (differenceInDays === 0) {
      return `Bu gün ${convertTo24HourFormat(timeString)}`;
    } else if (differenceInDays === 1) {
      return `Dünən ${convertTo24HourFormat(timeString)}`;
    } else {
      return `${formattedDateTime}`;
    }
  }
  function convertTo24HourFormat(timeStr) {
    // Parse the time in 12-hour AM/PM format
    const [hoursStr, minutesStr] = timeStr.split(":");
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    // Check if it's AM or PM and adjust the hours
    if (hours === 12) {
      // If it's 12:xx AM, set hours to 0
      hours = 0;
    } else if (timeStr.includes("PM")) {
      // If it's PM, add 12 to the hours
      hours += 12;
    }

    // Format the time in 24-hour format
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;

    return formattedTime;
  }
  return (
    <div className="back" style={{ borderTop: "1px solid green" }}>
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <h2>
        {details.announcementName} <span className="point"> </span>{" "}
        <span>{details.companyName}</span>
      </h2>
      <div className="main-detail">
        <div className="left">
          <div className="small_img">
            {details.photos &&
              details.photos.map((e, index) => (
                <div key={index}>
                  <img
                    src={e?.url}
                    alt={`${e.announcementSubCategoryName},kursline,kurslar,repititor`}
                    onClick={e => setMainimg(e.target.src)}
                  />
                </div>
              ))}
          </div>
          <div className="big_img">
            <img
              src={
                mainimg ? mainimg : details.photos ? details.photos[0]?.url : ""
              }
              alt={`kursline,kurslar,repititor`}
            />
          </div>
        </div>
        <div className="right">
          <div className="price">
            {/* <span style={{ color: "#78BFDB" }}>No:{id}</span> */}
            <span style={{ color: "#78BFDB", fontSize: "13px" }}>
              {datedif(details.announcementStartDate)}
            </span>
            <span className="blue_sp">
              <i className="fa-solid fa-eye "></i>
              {details.countView}
            </span>
            <button>{details.announcementPrice} AZN</button>
          </div>
          <div className="about">
            <ul style={{ padding: "0", margin: "0" }}>
              <li>
                <span className="blue_sp">Kateqoriya</span>{" "}
                <span className="green_sp">
                  {details.announcementCategoryName}
                </span>
              </li>
              <li>
                <span className="blue_sp">Altkateqoriya</span>{" "}
                <span className="green_sp">
                  {details.announcementSubCategoryName}
                </span>
              </li>
              <li>
                <span className="blue_sp">Region</span>{" "}
                <span className="green_sp">{details.announcementRegionId}</span>
              </li>
              <li>
                <span className="blue_sp">Rejim</span>{" "}
                <span className="green_sp">{details.isOnline}</span>
              </li>
              <li>
                <span className="blue_sp">Müəllim</span>{" "}
                <span className="green_sp">
                  {details.teacher && details.teacher[0] !== null
                    ? details.teacher.map((teacherName, index) => (
                        <p key={index}>{teacherName},</p>
                      ))
                    : "Qeyd edilməyib"}
                </span>
              </li>
              <li>
                <span className="blue_sp">Əlaqə</span>{" "}
                <span className="green_sp">{details.phone}</span>
              </li>
              <li>
                <span className="blue_sp">No:</span>
                <span className="green_sp">{id}</span>
              </li>
            </ul>
            {auth.user ? (
              auth.roles != 3 ? (
                auth?.user?.userInfo?.id === details?.checkId ? (
                  details.announcementSituationId !== 5 ? (
                    <div className="isvip">
                      {details.announcementSituationId === 1 ? (
                        <Link to={`/IreliCek/${id}`}>
                          <button className="sol">İrəli çək</button>
                        </Link>
                      ) : (
                        ""
                      )}
                      {details.announcementSituationId === 1 ? (
                        <Link to={`/Vip/${id}`}>
                          <button className="sag">VİP</button>
                        </Link>
                      ) : (
                        ""
                      )}
                      <button
                        className="crud bgred"
                        elanid={details.id}
                        onClick={handleDeleteAnnouncement}
                      >
                        Kursu sil
                      </button>
                      <Link to={`/EditAnnouncement/${id}`}>
                        <button className="crud bg_green">Redaktə et</button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="desc">
        <p>{details.announcementDesc}</p>
      </div>
      <div className="comment-container">
        <div className="new-comment">
          <div className="comment-form">
            <div className="comment-area">
              <div className="comment-form-input">
                <form onSubmit={handleComment}>
                  {auth.user != null ? (
                    <input
                      className="add-comment"
                      placeholder="Şərh əlavə edin"
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  ) : (
                    <></>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        {comments &&
          comments
            .slice()
            .reverse()
            .map(e => (
              <div className="comment" key={e.commentId}>
                <div className="comment-photo">
                  {e.commentPhoto ? (
                    <img src={e.commentPhoto} alt="kursline,repititor" />
                  ) : (
                    <img src={userimg} alt="" />
                  )}
                </div>
                <div className="comment-itself">
                  <div className="up-side">
                    <h5>{e.userFullName}</h5>
                  </div>
                  <p>{e.commentContent} </p>
                  {auth.user != null ? (
                    e.userId === auth.user.userInfo.id ? (
                      <i
                        className="fa-solid fa-xmark"
                        commentid={e.commentId}
                        onClick={handleDeleteComment}
                      ></i>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
      </div>
      <hr />

      <div className="cards">
        {details.announcements &&
          details.announcements.map(announcement => (
            <Card key={announcement.id} announcement={announcement} />
          ))}
      </div>
    </div>
  );
}
