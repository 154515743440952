import React, { useState } from "react";
import "../Admin/Admin.css";
import { useEffect } from "react";
import useAuth from "../../Hooks/useAuth";
import axios from "axios";
import Swal from "sweetalert2";

function AdminMessage() {
  const { auth } = useAuth();
  const [message, setMessage] = useState([]);
  const [mess, setMess] = useState([]);
  const [mdal, setModal] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://api.kursline.az/Admin/GetMessages/${auth.user.userInfo.id}`,
        {
          headers: { Authorization: `Bearer ${auth.user.accsessToken}` },
        }
      )
      .then(function (res) {
        setMessage(res.data);
      });
  }, []);
  const handleDelete = e => {
    const mesid = e.target.getAttribute("msid");

    Swal.fire({
      title: `Are you sure? ${mesid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/Admin/DeleteMessage/${auth?.user?.userInfo?.id}/${mesid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.status === 200) {
              const a = message.filter(x => x.messageId != mesid);
              setMessage(a);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Not Deleted!", `${res.response.data?.title}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  const openModal = e => {
    const mid = e.target.getAttribute("mid");
    const mes = message.filter(x => x.messageId == mid);
    setMess(mes);
    setModal(!mdal);
    if (mdal === false) {
      axios
        .post(
          `https://api.kursline.az/Admin/ViewMessageById/${auth?.user?.userInfo?.id}/${mid}`,
          "",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${auth?.user?.accsessToken}`,
            },
          }
        )
        .then(res => {
          console.log(res);
        });
    }
  };

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + "...";
    }
  }

  return (
    <div className="ad-mess-main" style={{ position: "relative" }}>
      <div>
        <table className="table table-dark table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Mobil nomrəsi</th>
              <th scope="col">İradlar</th>
              <th scope="col">Sil</th>
            </tr>
          </thead>
          <tbody>
            {message &&
              message.map(e => (
                <tr
                  key={e.messageId}
                  className={e.messageRead ? "" : "table-active"}
                >
                  <td>{e.messageId}</td>
                  <td>{e.messagePhone}</td>
                  <td>{truncateText(e.messageText, 15)}</td>
                  <td>
                    <i
                      className="fa-regular fa-eye"
                      style={{ color: "#22c32d" }}
                      mid={e.messageId}
                      onClick={e => openModal(e)}
                    ></i>
                    <i
                      className="fa-solid fa-trash-can"
                      msid={e.messageId}
                      onClick={handleDelete}
                      style={{
                        cursor: "pointer",
                        color: "red",
                        marginLeft: "21px",
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div
        className={mdal ? "modal2 aktive-modal" : "modal2"}
        // style={mdal ? { display: "block" } : { display: "none" }}
      >
        <i
          className="fa-regular fa-rectangle-xmark close-mdl"
          onClick={openModal}
        ></i>
        <div className="message-head">
          <h2>Mesajın mətni</h2>
        </div>
        <div className="message-body">
          {mess &&
            mess.map(e => (
              <div>
                <div>
                  {" "}
                  <span style={{ color: "black" }}>
                    Əlaqə nömrəsi : {e.messagePhone}
                  </span>
                </div>
                <div className="mesaj-metni">
                  <span>{e.messageText}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default AdminMessage;
