import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
function PaymentSucces() {
  const { auth } = useAuth();
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(
        `https://api.kursline.az/PostPaymentSuccess/${auth.user.userInfo.id}/${id}`,
        {
          headers: { Authorization: `Bearer ${auth.user.accsessToken}` },
        }
      )
      .then(function (res) {
        console.log(res);
      });
  }, []);
  return (
    <div className="bs-main">
      <div className="payment-success">
        <div className="animation-ctn">
          <div className="icon--order-success svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="154px"
              height="154px"
            >
              <g fill="none" stroke="#22AE73" stroke-width="2">
                <circle
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokedasharray: "480px, 480px",
                    strokedashoffset: "960px",
                  }}
                ></circle>
                <circle
                  id="colored"
                  fill="#22AE73"
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokedasharray: "480px, 480px",
                    strokedashoffset: "960px",
                  }}
                ></circle>
                <polyline
                  className="st0"
                  stroke="#fff"
                  stroke-width="10"
                  points="43.5,77.8 63.7,97.9 112.2,49.4 "
                  style={{
                    strokeDasharray: "100px , 100px",
                    strokedashoffset: "200px",
                  }}
                />
              </g>
            </svg>
          </div>
          <br />
          <h2>Payment Success</h2>
          <span>Your payment was successfully completed.</span>
        </div>
      </div>
    </div>
  );
}

export default PaymentSucces;
