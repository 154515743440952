import React, { useEffect, useState } from "react";
import useAuth from "./../../Hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
import img from "../../images/Frame 67.png";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import Swal from "sweetalert2";
import BounceLoader from "react-spinners/BounceLoader";
const Login = () => {
  const { setAuth } = useAuth();
  const [mob, setMob] = useState("");
  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [loading, setLoading] = useState(false);
  const [color] = useState("green");
  const [moberror, setMoberror] = useState(false);
  const override = {
    display: "block",
    color: "black ",
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setErrMsg("");
  }, []);
  const SendSms = async e => {
    e.preventDefault();
    console.log(mob);
    if (mob) {
      axios
        .post(
          `https://api.kursline.az/Autorization`,
          {
            number: mob,
          },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (res) {
          console.log(res);
          console.log(res.code);
          if (res.status == 200) {
            Swal.fire({
              allowOutsideClick: false,
              title: "Kodu daxil edin.",
              input: "text",
              inputPlaceholder: "Kod",
              inputAttributes: {
                autocapitalize: "off",
              },
              showCancelButton: true,
              cancelButtonText: "Leğv et",
              confirmButtonText: "Təsdiqlə",
              showLoaderOnConfirm: true,
              preConfirm: login => {
                return axios
                  .post(
                    `https://api.kursline.az/Login`,
                    {
                      number: mob,
                      code: login,
                    },
                    {
                      headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then(function (res) {
                    if (res.data != null) {
                      localStorage.clear();
                      localStorage.setItem(
                        "refreshToken",
                        res?.data.refreshToken.token
                      );

                      const roles = res.data.userInfo.userStatus;
                      const user = {
                        accsessToken: res.data.accessToken.token,
                        refreshToken: res.data.refreshToken.token,
                        isEmailVerified: res.data.isEmailVerified,
                        userInfo: res.data.userInfo,
                      };
                      setAuth({ user, roles });
                      const aut = {
                        user,
                        roles,
                      };
                      localStorage.setItem("Auth", JSON.stringify(aut));
                      setRedirect(true);
                      if (res.data.userInfo.userStatus === 4) {
                        navigate("/admin/dashboard", { replace: true });
                      } else {
                        navigate("/", { replace: true });
                      }
                      return res.status;
                    } else {
                      setAuth({});
                      setRedirect(false);
                      navigate("/");
                      Swal.showValidationMessage(`Kodu düzgün daxil edin!`);
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    console.log(err.code);
                    if (err.code === "ERR_BAD_REQUEST") {
                      console.log(err.response.data.code);
                      if (err.response.data.code === "OTP_KOD_ERROR") {
                        console.log(err.response.data.details.Time);
                        Swal.close(true);
                        Swal.fire({
                          icon: "error",
                          allowOutsideClick: false,
                          title: "ERROR",
                          text: err.response.data.details.Time,
                          color: "red",
                        });
                      } else {
                        Swal.showValidationMessage(`Kodu düzgün daxil edin!!!`);
                      }
                    } else {
                      Swal.showValidationMessage(`Kodu düzgün daxil edin!!!`);
                    }
                  });
              },
              // allowOutsideClick: () => !Swal.isLoading(),
            });
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.code);
          if (err.code === "ERR_BAD_REQUEST") {
            if (err.response.data.code === "OTP_ERROR") {
              console.log(err.response.data.details);
              Swal.fire(
                {
                  icon: "error",
                  allowOutsideClick: false,
                  title: "ERROR",
                  text: err.response.data.details.Login,
                  color: "red",
                }
                // `${err.response.data.details.Number}`,
              );
            }
          } else {
            // console.log(err.response.data.details.Number);
            Swal.fire(
              {
                icon: "error",
                allowOutsideClick: false,
                title: " Bir daha cəhd edin.",
                text: "Error",
                color: "red",
              }
              // `${err.response.data.details.Number}`,
            );
          }
        });
    } else {
      setMoberror(true);
      setTimeout(() => {
        setMoberror(false);
      }, 2000);
    }
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        alignitems: "center",
      }}
    >
      <BounceLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div className="formDiv">
      <div className="container log-cont">
        <div className="login-Photo">
          <img src={img} alt="kursline" />
        </div>
        <div className="login-Form">
          <form onSubmit={SendSms} style={{ borderRadius: "10px" }}>
            <ul>
              <li className="log-half">
                {errMsg ? (
                  <span style={{ color: "red" }}>{errMsg}</span>
                ) : (
                  <span></span>
                )}
              </li>
              <li
                className="half"
                style={{ width: "60% !important", margin: "auto" }}
              >
                <Input
                  country="AZ"
                  international
                  withCountryCallingCode
                  value={mob}
                  onChange={setMob}
                  id={"Mobile"}
                  required
                  style={
                    moberror
                      ? { border: "1px solid red" }
                      : mob?.length > 3 && !isValidPhoneNumber(mob)
                      ? { border: "1px solid red" }
                      : {}
                  }
                />
                <label
                  htmlFor="Mobile"
                  className={
                    mob && !isValidPhoneNumber(mob)
                      ? "redmob"
                      : mob
                      ? "inpac"
                      : ""
                  }
                >
                  {moberror ? (
                    <span> Zəhmət olmasa nömrəni daxil edin</span>
                  ) : (
                    "Telefon nömrəsi*"
                  )}
                </label>
              </li>
              <li className="mt-2" style={{ width: "60%", margin: "auto" }}>
                <button className="daxilol btn btn-success">Daxil olun</button>
              </li>{" "}
              <li
                style={{
                  margin: "10px auto",
                  textAlign: "center",
                  width: "90%",
                }}
              >
                <Link style={{ color: "green" }} to="/">
                  Kursline.az
                </Link>
                <p style={{ width: "100%", padding: "10px", color: "green" }}>
                  Siz qeydiyatdan keçməklə elan paylaşa, elanları bəyənə və şərh
                  yaza bilrəsiz
                </p>
              </li>
            </ul>{" "}
          </form>{" "}
        </div>
      </div>
    </div>
  );
};

export default Login;
