import React, { useState, useEffect, useContext } from "react";
import useAuth from "../../Hooks/useAuth";
import { Link } from "react-router-dom";
import axios from "axios";
import "../UserAnnouncements/UserAnnouncement.css";
import srimg from "../../images/Frame 65.jpg";
import { Helmet } from "react-helmet-async";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Fab from "@mui/material/Fab";
import { green } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import Card from "../../components/Card";
const fabGreenStyle = {
  color: "common.white",
  bgcolor: green[500],
  "&:hover": {
    bgcolor: green[600],
  },
};
function UserAnnouncements() {
  const { auth } = useAuth();
  const [userProfile, setUserProfile] = useState("");
  const [announcements, setAnnouncements] = useState([]);
  const { numAnnouncements } = useState(10);
  const [activeItem, setActiveItem] = useState(1);
  const [ofset, setOfset] = useState(0);
  const [Counts, sdetCount] = useState();

  const getCount = async () => {
    //api.kursline.az
    axios
      .get(
        `https://api.kursline.az/User/GetAnnouncementCount/${auth.user.userInfo.id}`,
        { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
      )
      .then(function (res) {
        console.log(res.data);
        sdetCount(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCount();
  }, []);
  const handleItemClick = index => {
    setOfset(0);
    if (activeItem === index) {
      // If the clicked item is already active, deactivate it
      setActiveItem(null);
    } else {
      // If a different item is active, deactivate it and activate the clicked item
      setActiveItem(index);
    }
  };
  const handleOfset = ofs => {
    setOfset(ofs);
    console.log(ofs);
    axios
      .get(
        `https://api.kursline.az/User/GetAnnouncements/${auth.user.userInfo.id}?limit=${numAnnouncements}&offset=${ofs}&IsActive=${activeItem}`,
        { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
      )
      .then(function (res) {
        if (res.data != null) {
          setAnnouncements([...announcements, ...res.data]);
        } else {
          setAnnouncements("");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (auth.roles === 3) {
      setUserProfile(
        <React.Fragment>
          <Link to="/UserProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/CompanyProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    }
    setAnnouncements("");
    if (activeItem === 5) {
      axios
        .get(
          `https://api.kursline.az/User/GetVipAnnouncements/${auth.user.userInfo.id}`,
          { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
        )
        .then(function (res) {
          if (res.data != null) {
            setAnnouncements(res.data);
          } else {
            setAnnouncements("");
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (activeItem === 6) {
      axios
        .get(
          `https://api.kursline.az/User/GetIreliAnnouncements/${auth.user.userInfo.id}`,
          { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
        )
        .then(function (res) {
          if (res.data != null) {
            setAnnouncements(res.data);
          } else {
            setAnnouncements("");
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `https://api.kursline.az/User/GetAnnouncements/${auth.user.userInfo.id}?limit=${numAnnouncements}&offset=${ofset}&IsActive=${activeItem}`,
          { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
        )
        .then(function (res) {
          if (res.data != null) {
            if (activeItem === 1) {
              setOfset(10);
            } else {
              setOfset(0);
            }
            setAnnouncements(res.data);
          } else {
            setAnnouncements("");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [activeItem]);

  function truncateText(text, maxLength) {
    if (text !== null) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.substring(0, maxLength) + "...";
      }
    }
  }

  return (
    <div className="bs-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <div className="user-navbar">
        {userProfile}
        <Link to="/MyAnnouncments" className="user-navbar-announcements">
          Mənim elanlarım
        </Link>
      </div>
      <div className="elan-main">
        <div className="elan-main-nav ProfilElanlar">
          <ButtonGroup
            className="buttonGroupDerc"
            variant="contained"
            aria-label="Basic button group"
          >
            <Button
              onClick={() => handleItemClick(1)}
              className={activeItem === 1 ? "activeted" : ""}
              sx={fabGreenStyle}
            >
              Aktİv kurslar{Counts?.active > 0 ? `(${Counts.active})` : ""}
            </Button>
            <Button
              onClick={() => handleItemClick(3)}
              className={activeItem === 3 ? "activeted" : ""}
              sx={fabGreenStyle}
            >
              {" "}
              Gözləmədə olan{Counts?.vaiting > 0 ? `(${Counts.vaiting})` : ""}
            </Button>
            <Button
              sx={fabGreenStyle}
              onClick={() => handleItemClick(2)}
              className={activeItem === 2 ? "activeted" : ""}
            >
              {" "}
              Deaktiv{Counts?.deaktive > 0 ? `(${Counts.deaktive})` : ""}
            </Button>
            <Button
              sx={fabGreenStyle}
              onClick={() => handleItemClick(4)}
              className={activeItem === 4 ? "activeted" : ""}
            >
              {" "}
              Düzəliş gözləyənlər
              {Counts?.vaitingEdit > 0 ? `(${Counts?.vaitingEdit})` : ""}
            </Button>
            <Button
              sx={fabGreenStyle}
              onClick={() => handleItemClick(5)}
              className={activeItem === 5 ? "activeted" : ""}
            >
              {" "}
              Vip
            </Button>
            <Button
              sx={fabGreenStyle}
              onClick={() => handleItemClick(6)}
              className={activeItem === 6 ? "activeted" : ""}
            >
              {" "}
              İrəlİ çəkİlmİş
            </Button>
          </ButtonGroup>
          <Link
            className="newAdButtonMobileNone"
            to="/newannouncement"
            style={{ marginLeft: "10px" }}
          >
            <Fab
              sx={fabGreenStyle}
              size="small"
              color="secondary"
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </Link>
        </div>
        <div className="elanlar">
          <div className="user-cards">
            {announcements.length > 0 ? (
              announcements.map(e => (
                <Card key={e.id} announcement={e} />
                // <div className="card" key={e.id}>
                //   <div className="content">
                //     <div className="buttons">
                //       <div className="button_1">
                //         <button className="but11">{e.isOnline}</button>
                //         {/* <button className="but12">{e.isRejim}</button> */}
                //       </div>
                //       <div className="icons">
                //         {e.IsVIP ? <i className="fa-solid fa-gem"></i> : ""}
                //         {/* <i className="fa-solid fa-gem"></i> */}
                //       </div>
                //     </div>
                //     <div className="image">
                //       {/* <NavLink to={`/Admin/Elan/${e.id}`}> */}
                //       <Link
                //         // className="postDesc"
                //         to={`/announcementdetails/${e.id}`}
                //       >
                //         <img src={e.photos[0]?.url} alt="elan" />
                //       </Link>
                //     </div>
                //     <div className="desc">
                //       <h3>{truncateText(e.announcementName, 10)}</h3>
                //       <h5>{truncateText(e.companyName, 10)}</h5>
                //       <p>{truncateText(e.announcementDesc, 10)}</p>
                //     </div>
                //     {/* <div style={{ width: "100%", padding: " 0 10px" }}>
                //       <Link
                //         className="postDesc"
                //         to={`/announcementdetails/${e.id}`}
                //       >
                //         Details
                //       </Link>
                //     </div> */}
                //   </div>
                // </div>
              ))
            ) : (
              <div className="user-null">
                <div className="user-srimg">
                  <h3></h3>
                  <img src={srimg} alt="elan, kursline" />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "20px 0",
            }}
          >
            {/* <Button onClick={(e) => handleOfset(ofset + 10)} sx={fabGreenStyle}>
              {" "}
              Daha çox
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAnnouncements;
