import React, { useState, useEffect } from "react";
import "./Contact.css";
import "react-phone-number-input/style.css";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import axios from "axios";
import Swal from "sweetalert2";

function Contact() {
  const [value, setValue] = useState();
  const [message, setMessage] = useState("");
  const [ermessage, seterMessage] = useState("");
  const [showFalse, setShowFalse] = useState(false);

  
  const SendMessage = e => {
    e.preventDefault();

    if (value) {
      if (!isValidPhoneNumber(value)) {
        setShowFalse(true);
       
            setTimeout(() => {
              setShowFalse(false);
            }, 5000); // 5 saniye sonra false değeri gizlenir
            return; // Telefon numarası geçerli değilse post işlemi yapma
          
        
      }
    }
      axios
        .post(
          `https://api.kursline.az/PostMessage?tel=${value}&message=${message}`
        )
        .then(res => {
          if (res.status === 200) {
            setValue("");
            setMessage("");
            Swal.fire({
              icon: "success",
              title: "Uğurlu",
              text: "Mesajınız uğurla göndərildi",
              allowOutsideClick: false,
            });
          }
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "Telefon və mövzu  hissəsi boş ola bilməz...",
            allowOutsideClick: false,
          });
        });
  
   

  };

  return (
    <div className="contact-main">
      <div className="cnt-header">
        <h1 style={{ color: "#25A244" }}>Bizə yazın</h1>
      </div>
      <div className="cnt-body">
        <form className="cnt-form" onSubmit={SendMessage}>
          <div className="cnt-items sc-item">
            <label>
              Tel:{" "}
              {showFalse ? (
                <span style={{ color: "red" }}>*</span>
              ) : value && isValidPhoneNumber(value) ? (
                <span style={{ color: "green" }}>
                  <i className="fa-solid fa-check"></i>
                </span>
              ) : null}
            </label>
            <Input
              country="AZ"
              international
              withCountryCallingCode
              value={value}
              onChange={setValue}
              style={
                showFalse
                  ? { border: "1px solid red" }
                  : value && !isValidPhoneNumber(value)
                  ? { border: "1px solid red" }
                  : {}
              }
            />
          </div>
          <div className="cnt-items">
            <textarea
              id="Textarea"
              placeholder="Mövzu"
              value={message}
              // required
              onChange={e => {
                setMessage(e.target.value);
              }}
            ></textarea>
          </div>
          {ermessage ? <span style={{ color: "red" }}>{ermessage}</span>:""}
          <div>
            <button className="btn btn-success" style={{ marginTop: "10px" }}>
              Göndər
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
