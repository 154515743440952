import React, { useState } from "react";
import "../Admin/Admin.css";
import { useEffect } from "react";
import useAuth from "../../Hooks/useAuth";
import axios from "axios";
import Swal from "sweetalert2";
import "../Register/register.css";
// const initialFormData = {};

function Reklamlar() {
  const { auth } = useAuth();
  const [message, setMessage] = useState();
  const [mdal, setModal] = useState(false);
  const [photoerror, setPhotoerror] = useState("");
  const [text, setText] = useState("");
  const [texterr, setTexterr] = useState();
  const [link, setLink] = useState("");
  const [linkerr, setLinkerr] = useState();
  const [photo, setPhoto] = useState("");
  const [imgp, setImgp] = useState();
  useEffect(() => {
    axios
      .get(
        `https://api.kursline.az/Admin/GetReklamlar/${auth.user.userInfo.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        setMessage(res.data);
      });
  }, []);
  const handlePhotoChange = event => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImgp(imageUrl);
    if (file && file.size <= 3 * 1024 * 1024) {
      // Set formData.photo to the selected File object
      setPhoto(file);
    }
  };
  const handleDelete = e => {
    const mesid = e.target.getAttribute("msid");

    Swal.fire({
      title: `Are you sure? ${mesid}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        axios
          .post(
            `https://api.kursline.az/Admin/DeleteReklamlar/${auth?.user?.userInfo?.id}/${mesid}`,
            "",
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${auth?.user?.accsessToken}`,
              },
            }
          )
          .then(res => {
            if (res.data.iSuccess === true) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              const a = message.filter(x => x.reklamId != mesid);
              setMessage(a);
            } else {
              Swal.fire("Deleted!", `${res.data?.details}`, "error");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  const openModal = e => {
    setModal(!mdal);
    setImgp("");
    setLink("");
    setText("");
    setPhoto("");
  };
  const handleSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("ReklamText", text);
    formData.append("ReklamLink", link);
    formData.append("ReklamPhoto", photo);

    axios
      .post(
        `https://api.kursline.az/Admin/PostReklamlar/${auth.user.userInfo.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (res) {
        const a = {
          reklamId: res.data.reklamId,
          reklamLink: res.data.reklamLink,
          reklamPhoto: res.data.reklamPhoto,
          reklamText: res.data.reklamText,
        };

        setMessage(e => [...e, a]);
        setImgp("");
        setLink("");
        setText("");
        setPhoto("");
        openModal();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="ad-mess-main" style={{ position: "relative" }}>
      <div className="table-responsive">
        <table className="table table-dark table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Reklam Mətni</th>
              <th scope="col">Reklam şəkli</th>
              <th scope="col">Link</th>
              <th scope="col" style={{ cursor: "pointer" }} onClick={openModal}>
                Reklam əlavə et
              </th>
            </tr>
          </thead>
          <tbody>
            {message &&
              message.map((e, index) => (
                <tr
                  key={e.reklamId}
                  className={e.reklamId ? "" : "table-active"}
                >
                  <td>{index + 1}</td>
                  <td>{e.reklamText}</td>
                  <td>
                    <img src={e.reklamPhoto} style={{ width: "100px" }} />
                  </td>
                  <td>{e.reklamLink}</td>
                  <td>
                    <i
                      className="fa-solid fa-trash-can"
                      msid={e.reklamId}
                      onClick={handleDelete}
                      style={{ cursor: "pointer", color: "darkred" }}
                    ></i>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div
        className={mdal ? "modal aktive-modal" : "modal"}
        // style={mdal ? { display: "block" } : { display: "none" }}
      >
        <i
          className="fa-regular fa-rectangle-xmark close-mdl"
          onClick={openModal}
        ></i>
        <form className="add-reklam-main" onSubmit={handleSubmit}>
          <div className="add-reklam-head">
            <h2>Yeni reklam əlavə edin</h2>
          </div>
          <div className="add-reklam-body">
            <div className="half">
              <label htmlFor="link" style={{ color: "black" }}>
                Link*
              </label>
              <input
                style={linkerr ? { border: "1px solid red" } : {}}
                type="text"
                id="link"
                autoComplete="off"
                onChange={e => setLink(e.target.value)}
                value={link}
                required
                placeholder=""
              />
              {link ? (
                <span></span>
              ) : linkerr ? (
                <span className="error">*{linkerr}</span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="half">
              <label htmlFor="username" style={{ color: "black" }}>
                Mətn*
              </label>
              <input
                style={texterr ? { border: "1px solid red" } : {}}
                type="text"
                id="username"
                autoComplete="off"
                onChange={e => setText(e.target.value)}
                value={text}
                required
                placeholder=""
              />
              {text ? (
                <span></span>
              ) : texterr ? (
                <span className="error">*{texterr}</span>
              ) : (
                <span></span>
              )}
            </div>

            <div className="add-reklam-photo">
              <div className="photo">
                {photo ? (
                  <span></span>
                ) : photoerror ? (
                  <span className="error">*{photoerror}</span>
                ) : (
                  <span></span>
                )}
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="input-file"
                  onChange={handlePhotoChange}
                />
                <label
                  htmlFor="file"
                  className={
                    photo
                      ? "btn btn-tertiary js-labelFile has-file"
                      : "btn btn-tertiary js-labelFile"
                  }
                >
                  <i className="icon fa fa-check"></i>
                  <span className="js-fileName">
                    {photo ? photo.name : "Profil sekli"}
                  </span>
                </label>
              </div>
              <div className="add-reklam-img">
                <img src={imgp} />
              </div>
            </div>
          </div>
          <div className="add-reklam-footer">
            <button className="btn btn-success">Əlavə et</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Reklamlar;
