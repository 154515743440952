import React, { useState, useEffect } from "react";
import useAuth from "../../Hooks/useAuth";
import axios from "axios";

function Orders() {
  const { auth } = useAuth();
  const [orders, setOrders] = useState([]);
  const [ofset, setOfset] = useState(0);

  const GetOrders = () => {
    axios
      .get(
        `https://api.kursline.az/Admin/GetPaymentsInfo/${auth.user.userInfo.id}/${ofset}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(function (res) {
        if (orders != null) {
          setOrders([...orders, ...res.data]);
          // setOrders(res.data);
        } else {
          setOrders(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetOrders();
  }, [ofset]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setOfset(prev => prev + 30);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  return (
    <div className="ad-mess-main" style={{ position: "relative" }}>
      <div>
        <table className="table table-dark table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Order Id</th>
              <th scope="col">Elan Id</th>
              <th scope="col">Order Key</th>
              <th scope="col">Order Session</th>
              <th scope="col">Order Status</th>
              <th scope="col">Order Vip</th>
              <th scope="col">Order Irelicek</th>
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.map((e, index) => (
                <tr
                  key={e.orderId}
                  className={e.reklamId ? "" : "table-active"}
                >
                  <td>{index + 1}</td>
                  <td>{e.orderId}</td>
                  <td>{e.announcementId}</td>
                  <td>{e.orderKey}</td>
                  <td>{e.orderSessionId}</td>
                  <td>
                    {e.orderStatus ? (
                      <span style={{ color: "green" }}>
                        {" "}
                        <i
                          className="fa-regular fa-circle-check"
                          style={{ color: "#00cc0e", marginRight: "3px" }}
                        ></i>
                        Success
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        <i
                          className="fa-solid fa-circle-exclamation"
                          style={{ color: "#ff0000", marginRight: "3px" }}
                        ></i>
                        Not Paid
                      </span>
                    )}
                  </td>
                  <td>{e.vipId}</td>
                  <td>{e.ireliCekId}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Orders;
