import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Card from "./Card";
function CommanView() {
  const { id } = useParams();
  const [announcements, setAnnouncements] = useState([]);
  const [title, setTitle] = useState();
  const GetVipElanlar = () => {
    axios
      .get("https://api.kursline.az/GetVipAnnouncments")
      .then(res => {
        console.log(res.status);
        setAnnouncements(res.data);
        setTitle("VIP");
      })
      .catch(err => {
        console.log(err);
      });
  };
  const GetNewElanlar = () => {
    axios
      .get("https://api.kursline.az/GetNewAnnouncments")
      .then(res => {
        console.log(res.status);
        setAnnouncements(res.data);
        setTitle("Son paylaşılan");
      })
      .catch(err => {
        console.log(err);
      });
  };
  const GetViewElanlar = () => {
    axios
      .get("https://api.kursline.az/GetMostViewedAnnouncments")
      .then(res => {
        console.log(res.status);
        setAnnouncements(res.data);
        setTitle("Ən çox baxılan");
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id == 1) {
      GetNewElanlar();
    } else if (id == 2) {
      GetViewElanlar();
    } else if (id == 4) {
      GetVipElanlar();
    } else {
      setAnnouncements();
    }
  }, [id]);

  return (
    <div className="home-main-body">
      <div className="home-reklam-cont"></div>
      <div className="home-elanlar-cont">
        <div className="isvip" style={{ marginTop: "10px" }}>
          <div className="vip">
            <h3>{title} Elanlar</h3>
          </div>
        </div>
        <div className="cards">
          {announcements &&
            announcements.map(announcement => (
              <Card key={announcement.id} announcement={announcement} />
            ))}
        </div>
      </div>
      <div className="home-reklam-cont"></div>
    </div>
  );
}

export default CommanView;
