import { useParams } from "react-router-dom"

function Post() {
    const {id} = useParams()
    console.log(id);
    return(
        <>
        <h1>Post</h1>
        </>
    )
}

export default Post