import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";

function EditAnnouncement() {
  const { id } = useParams();
  const { auth } = useAuth();
  const [user, setUser] = useState();
  const [regions, setRegions] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [userProfile, setUserProfile] = useState("");
  const [online, setOnline] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || `/announcementdetails/${id}`;

  const [announcementName, setAnnouncementName] = useState("");
  const [announcementDesc, setAnnouncementDesc] = useState("");
  const [announcementPrice, setAnnouncementPrice] = useState();
  const [announcementCategoryId, setAnnouncementCategoryId] = useState();
  const [announcementIsOnlineId, setAnnouncementIsOnlineId] = useState();
  const [announcementSubCategoryId, setAnnouncementSubCategoryId] = useState();
  const [announcementRegionId, setAnnouncementRegionId] = useState();
  const [photos, setPhotos] = useState([]);
  const [img, setimg] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [nameerror, setNameerror] = useState("");
  const [descerror, setDescerror] = useState("");
  const [priceerror, setPriceerror] = useState("");
  const [caterror, setCaterror] = useState("");
  const [onlerror, setOnlerror] = useState("");
  const [subcaterror, setSubcaterror] = useState("");
  const [regerror, setRegerror] = useState("");
  const [adresserror, setAdresserror] = useState("");

  useEffect(() => {
    if (auth.user != null) {
      setUser(true);
    }
    if (auth.roles === 3) {
      setUserProfile(
        <React.Fragment>
          <Link to="/UserProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/CompanyProfile" className="user-navbar-profile">
            Mənim profilim
          </Link>
        </React.Fragment>
      );
    }
    axios
      .get(`https://api.kursline.az/GetAnnouncementOptions`)
      .then(response => {
        setRegions(
          response.data.regions.map(region => ({
            label: region.regionName,
            value: region.regionId,
          }))
        );
        setCategory(
          response.data.categories.map(categories => ({
            label: categories.categoryName,
            value: categories.categoryId,
          }))
        );
        setOnline(
          response.data.isOnlines.map(isOnlines => ({
            label: isOnlines.isOnlineName,
            value: isOnlines.isOnlineId,
          }))
        );
        const subCategories = response.data.categories.flatMap(
          category => category.subCategories
        );
        setSubCategories(subCategories);
      })
      .catch(error => {
        console.log(error);
      });
    axios
      .get(
        `https://api.kursline.az/GetAnnouncementForUserById/${auth?.user?.userInfo?.id}/${id}`,

        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
          },
        }
      )
      .then(response => {
        setAnnouncementName(response.data.announcementName);
        setAnnouncementCategoryId(response.data.announcementCategoryId);
        setAnnouncementDesc(response.data.announcementDesc);
        setAnnouncementIsOnlineId(parseInt(response.data.isOnline));
        setAnnouncementPrice(response.data.announcementPrice);
        setAnnouncementRegionId(response.data.announcementRegionId);
        setAnnouncementSubCategoryId(response.data.announcementSubCategoryId);
        const teacherArray = JSON.stringify(response.data.teacher)
          .replace(/[\[\]\"']/g, "")
          .split(",");

        console.log(teacherArray);
        if (teacherArray[0] !== null) {
          setTeachers([teacherArray]);
        } else {
          setTeachers(["mellim"]);
        }
        console.log(teacherArray[0]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [user]);
  const handlePhotoChange = event => {
    const files = event.target.files;
    const updatedPhotos = [];
    const updatedPhotos2 = [];
    let processedFiles = 0;

    // Iterate through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setimg(x => ({ ...x, [processedFiles]: file }));
      // Check if the file is not empty and its size is within the limit (3 MB)
      updatedPhotos.push(file);
      processedFiles++;
      if (processedFiles === files.length) {
        setPhotos(prevPhotos => [...prevPhotos, ...updatedPhotos]);
        // setimg(prevPhotos => [...prevPhotos, ...updatedPhotos]);
      }
    }
  };
  const handleRemovePhoto = index => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };

  const handleTeacherChange = (index, value) => {
    // Update the teacher name at the given index
    const updatedTeachers = [...teachers];
    updatedTeachers[index] = value;
    setTeachers(updatedTeachers);
  };

  const addTeacherInput = () => {
    setTeachers([...teachers, ""]); // Add an empty teacher input
  };

  const removeTeacherInput = index => {
    const updatedTeachers = [...teachers];
    updatedTeachers.splice(index, 1); // Remove the teacher input at the given index
    setTeachers(updatedTeachers);
  };

  const handleUpdateAnnouncement = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("AnnouncementName", announcementName);
    formData.append("AnnouncementDesc", announcementDesc);
    formData.append("AnnouncementPrice", announcementPrice);
    formData.append("AnnouncementCategoryId", announcementCategoryId);
    formData.append("AnnouncementSubCategoryId", announcementSubCategoryId);
    formData.append("AnnouncementRegionId", announcementRegionId);
    formData.append("AnnouncementIsOnlineId", parseInt(announcementIsOnlineId));
    if (teachers.length > 2) {
      formData.append("Teacher", teachers);
    }
    console.log(teachers.length);
    if (photos != null) {
      for (let i = 0; i < photos.length; i++) {
        formData.append("Photos", photos[i]);
      }
    }
    axios
      .post(
        `https://api.kursline.az/PutAnnouncement/${auth.user.userInfo.id}/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.user.accsessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(res => {
        if (res.status == 201) {
          Swal.fire({
            icon: "success",
            allowOutsideClick: false,
            title: "Uğurlu",
            text: "Elan yoxlandıqdan sonra dərc olunacaqdır!",
            preConfirm: e => {
              setRedirect(true);
              navigate("/MyAnnouncments");
            },
          });
        } else if (res.status == 400) {
        }
      })
      .catch(res => {
        if (res.response.status === 400) {
          setNameerror(res.response.data.details.AnnouncementName);
          setSubcaterror(res.response.data.details.AnnouncementSubCategoryId);
          setCaterror(res.response.data.details.AnnouncementCategoryId);
          setRegerror(res.response.data.details.AnnouncementRegionId);
          setDescerror(res.response.data.details.AnnouncementDesc);
          setPriceerror(res.response.data.details.AnnouncementPrice);
          setOnlerror(res.response.data.details.AnnouncementIsOnlineId);
        }
      });
  };

  return (
    <div className="bs-main">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <div className="user-navbar">
        {userProfile}
        <Link to="/MyAnnouncements" className="user-navbar-announcements">
          Mənim elanlarım
        </Link>
      </div>
      {/* <div className="elan-main"></div> */}

      <div className="container">
        <form onSubmit={handleUpdateAnnouncement}>
          <div className="newannouncement">
            <div className="new-itemdiv">
              <div>
                <select
                  className="form-select"
                  value={announcementCategoryId}
                  onChange={e => setAnnouncementCategoryId(e.target.value)}
                  style={
                    announcementCategoryId
                      ? {}
                      : caterror
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <option value="">Kateqoriya seçin</option>
                  {category &&
                    category.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <select
                  className="form-select"
                  value={announcementSubCategoryId}
                  onChange={e => setAnnouncementSubCategoryId(e.target.value)}
                  style={
                    announcementSubCategoryId
                      ? {}
                      : subcaterror
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <option value="">Alt kateqoriya seçin</option>
                  {announcementCategoryId &&
                    subCategories &&
                    subCategories
                      .filter(
                        x =>
                          x.subCategoryCategoryId ===
                          parseInt(announcementCategoryId)
                      )
                      .map(e => (
                        <option
                          key={e.subCategoryId}
                          selected={
                            e.subCategoryId === announcementSubCategoryId
                          }
                          value={e.subCategoryId}
                        >
                          {e.subCategoryName}
                        </option>
                      ))}
                </select>
              </div>
              <div>
                <select
                  value={announcementRegionId}
                  className="form-select"
                  onChange={e => setAnnouncementRegionId(e.target.value)}
                  style={
                    announcementRegionId
                      ? {}
                      : regerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <option value="">Region seç</option>
                  {regions &&
                    regions.map(e => (
                      <option key={e.value} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="new-itemdiv">
              <div>
                <select
                  className="form-select"
                  onChange={e => setAnnouncementIsOnlineId(e.target.value)}
                  style={
                    announcementIsOnlineId
                      ? {}
                      : onlerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <option value="">Rejimi seçin</option>
                  {online &&
                    online.map(e => (
                      <option
                        key={e.value}
                        value={e.value}
                        selected={e.value == announcementIsOnlineId}
                      >
                        {e.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-input ">
                <label htmlFor="AnnouncmentName">
                  {" "}
                  {announcementName ? (
                    "Elanın adı"
                  ) : nameerror ? (
                    <span className="error">*{nameerror}</span>
                  ) : (
                    "Elanın adı"
                  )}
                </label>
                <input
                  value={announcementName}
                  id="AnnouncmentName"
                  onChange={e => setAnnouncementName(e.target.value)}
                  style={
                    announcementName
                      ? {}
                      : nameerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                />
              </div>
              <div className="form-input ">
                <label htmlFor="announcementName">
                  {announcementPrice ? (
                    "Elanın qiyməti"
                  ) : priceerror ? (
                    <span className="error">*{priceerror}</span>
                  ) : (
                    "Elanın qiyməti"
                  )}
                </label>
                <input
                  style={
                    announcementPrice
                      ? {}
                      : priceerror
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="number"
                  className="announinput"
                  value={announcementPrice}
                  id="announcementName"
                  onChange={e => setAnnouncementPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="new-teacher">
              {teachers.map((teacher, index) => (
                <div key={index} className="new-teacher-in">
                  <label>Mellimler</label>
                  <input
                    type="text"
                    id={`teacherInput-${index}`}
                    value={teacher != "null" ? teacher : ""}
                    onChange={e => handleTeacherChange(index, e.target.value)}
                  />
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => removeTeacherInput(index)}
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
              <button
                className="new-teacher-btn"
                type="button"
                onClick={addTeacherInput}
              >
                +
              </button>
            </div>

            <div className="aboutCourse">
              <textarea
                label="Kurs haqqında"
                style={
                  announcementDesc
                    ? {}
                    : descerror
                    ? { border: "1px solid red" }
                    : {}
                }
                placeholder={
                  announcementDesc
                    ? "Kurs haqqında"
                    : descerror
                    ? `${descerror}`
                    : "Kurs haqqında"
                }
                value={announcementDesc}
                onChange={e => {
                  setAnnouncementDesc(e.target.value);
                }}
                setValue={setAnnouncementDesc}
              />
            </div>
          </div>
          <div className="edit-Photos">
            <label htmlFor="photoInput">Şəkil seç</label>
            <input
              type="file"
              id="photoInput"
              style={{ display: "none" }}
              accept=".png, .jpeg, .jpg"
              onChange={handlePhotoChange}
            />
            {photos.length > 0 && (
              <div className="new-img-main">
                <ul className="new-img-ul">
                  {photos.map((photo, index) => (
                    <li key={index}>
                      <img
                        key={index}
                        src={URL.createObjectURL(photo)}
                        alt={`Selected Photo ${index + 1}`}
                        style={{ maxWidth: "200px", height: "200px" }}
                      />
                      <button
                        className="delete-button"
                        onClick={() => handleRemovePhoto(index)}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="courseBtn">
            <button>Kursu əlavə et</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditAnnouncement;
