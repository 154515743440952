import "../navbar/Navbar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import useAuth from "./../../Hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faGem } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import vip from "../../images/new-removebg-preview.png";
import liked from "../../images/newVip.png";
import newelan from "../../images/arrowred.jpg";

export default function Navbar() {
  const { auth, setAuth } = useAuth();
  const [userProfile, setUserProfile] = useState("");
  const [high, setHigh] = useState("");
  const navigate = useNavigate();
  const [color, setColor] = useState("#78BFDB");
  const controller = useRef(null);
  const handleLogOut = () => {
    setAuth("");
    localStorage.clear();
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (auth.user !== null) {
      if (auth.roles === 2 || auth.roles === 1) {
        setUserProfile(
          <React.Fragment>
            <Link to="/contact">
              <i className="fa-solid fa-envelope"></i> Bizə yazın
            </Link>
            <Link to="/CompanyProfile">
              <i className="fa-solid fa-user"></i>
            </Link>
            <Link to="/MyFavorites">
              <i className="fa-regular fa-heart"></i>
            </Link>
            <span
              onClick={handleLogOut}
              style={{ cursor: "pointer", padding: "0 7px", fontSize: "20px" }}
            >
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </React.Fragment>
        );
      } else if (auth.roles === 3) {
        setUserProfile(
          <React.Fragment>
            <Link to="/contact">
              <i className="fa-solid fa-envelope"></i> Bizə yazın
            </Link>
            <Link to="/UserProfile">
              <i className="fa-solid fa-user"></i>
            </Link>
            <Link to="/MyFavorites">
              <i className="fa-regular fa-heart"></i>
            </Link>
            <span
              onClick={handleLogOut}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                padding: "0 7px",
                marginLeft: "4px",
              }}
            >
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </React.Fragment>
        );
      } else if (auth.roles === 4) {
        setUserProfile(
          <React.Fragment>
            <Link to="/contact">
              <i className="fa-solid fa-envelope"></i> Bizə yazın
            </Link>
            <Link to="/CompanyProfile">
              <i className="fa-solid fa-user"></i>
            </Link>
            <Link to="/MyFavorites">
              <i className="fa-regular fa-heart"></i>
            </Link>
            <span
              onClick={handleLogOut}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                padding: "0 7px",
                marginLeft: "4px",
              }}
            >
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </React.Fragment>
        );
      } else {
        setUserProfile(
          <React.Fragment>
            <Link to="/contact">
              <i className="yazin fa-solid fa-envelope"></i> Bizə yazın
            </Link>
            <Link to="/Login">Daxil ol</Link>
          </React.Fragment>
        );
      }
    } else {
      setUserProfile(
        <React.Fragment>
          <Link to="/contact">
            <i className="yazin fa-solid fa-envelope"></i> Bizə yazın
          </Link>
          <Link to="/Login">Daxil ol</Link>
        </React.Fragment>
      );
    }
  }, [auth]);
  useEffect(() => {
    controller.current = new AbortController();
    axios
      .get(`https://api.kursline.az/GetHighLights`, {
        signal: controller.current.signal,
      })
      .then(response => {
        setHigh(response.data[0]);
      })
      .catch(error => {
        // console.log(error);
      });
    return () => {
      controller.current.abort(); // Unmount sırasında iptal et
    };
  }, []);

  var settings = {
    dots: false,
    className: "center",
    // centerMode: true,
    infinite: true,
    autoplay: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    pauseOnHover: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <nav>
        <div className="nav-bar">
          <div className="navnavMessage">
            <h1 className="h1-top-new">
              <Link
                to="/"
                style={{
                  height: "80%",
                  color: "white",
                  padding: "5px 7px",
                  fontSize: "17px",
                  border: "1px solid whitesmoke",
                  borderRadius: "7px",
                }}
              >
                KursLine.az
                {/* <img src={logo} alt="kursline.az kursline kurs" /> */}
              </Link>
            </h1>
            <Link to="/contact" className="mobLink">
              <i class="fa-regular fa-envelope mobMessage"></i>
            </Link>
          </div>
          <div className="nav-us-info">
            <div className="search-bar-login">{userProfile}</div>
          </div>
        </div>

        <div className="search-bar">
          <div className="new-ad-left">
            <div className="mob-hight">
              {/* <Slider {...settings}> */}
              <Link to="/HighLights/2">
                <div className="vipShekil">
                  <span> {high.mostView}- Ən çox baxılan</span>
                  <img src={newelan} alt="" />
                </div>
              </Link>
              <Link to="/HighLights/1">
                <div className="yeniElanSekli">
                  <span>{high.newCourse} - Yeni kurs</span>
                  <img src={vip} alt="" />
                </div>
              </Link>
              <Link to="/HighLights/4">
                <div className="vipShekil">
                  <span>{high.vip} - VIP kurs</span>
                  <img src={liked} alt="" />
                </div>
              </Link>
              {/* </Slider> */}
            </div>
            <div className="hg-first">
              <p>
                <Link to="/HighLights/2" className="enCox">
                  {/* <FontAwesomeIcon icon={faEye} style={{ color }} /> */}
                  Ən çox baxılan &nbsp; <span>{high.mostView}</span>
                </Link>
              </p>
              <p>
                <Link to="/HighLights/1">
                  {/* <span className="material-symbols-outlined">
                    &nbsp;fiber_new
                  </span> */}
                  Ən son paylaşılan &nbsp; <span>{high.newCourse}</span>
                </Link>
              </p>

              <p>
                <Link to="/HighLights/4">
                  {/* <FontAwesomeIcon icon={faGem} style={{ color }} /> */}
                  VIP &nbsp; <span>{high.vip}</span>
                </Link>
              </p>
            </div>
            <div className="new-ad-right hg-second">
              {auth && auth.user ? (
                <Link to="/newannouncement">
                  <i className="fa-solid fa-circle-plus"></i> Yeni elan
                </Link>
              ) : (
                <Link to="/Elan-əlavə-et">
                  <i className="fa-solid fa-circle-plus"></i> Yeni elan
                </Link>
              )}
            </div>
            {/* <hr
              style={{
                border: "none",
                width: "100%",
                height: "2px",
                backgroundColor: "green",
              }}
            /> */}
          </div>
        </div>
        <div className="smoke-line"></div>
      </nav>
    </>
  );
}
