import axios from "axios";
import React, { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import Swal from "sweetalert2";

function Companies() {
  const { auth } = useAuth();
  const [companies, setCompanies] = useState([]);

  const handleAktivate = e => {
    const mesid = e.target.getAttribute("cmid");
    axios
      .post(`https://api.kursline.az/Admin/DeleteCompany?id=${mesid}`, "", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${auth?.user?.accsessToken}`,
        },
      })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          const updatedComments = companies.filter(x => x.companyId != mesid);
          setCompanies(updatedComments);
          //   Swal.fire("Deleted!", "Your file has been deleted.", "success");
          Swal.fire(`${res.data?.isSuccess}`, "success");
        } else {
          Swal.fire(`${res.data?.isSuccess}`, "Error");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(
        `https://api.kursline.az/Admin/GetCompanies/${auth.user.userInfo.id}`,
        { headers: { Authorization: `Bearer ${auth.user.accsessToken}` } }
      )
      .then(res => {
        setCompanies(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="ad-mess-main" style={{ position: "relative" }}>
      <div>
        <table className="table table-dark table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Sekli</th>
              <th scope="col">Adı</th>
              <th scope="col">Kategoriasi</th>
              <th scope="col">Telefon</th>
              <th scope="col">Aktiv/Block</th>
            </tr>
          </thead>
          <tbody>
            {companies &&
              companies.map((e, index) => (
                <tr
                  key={e.companyId}
                  className={e.reklamId ? "" : "table-active"}
                >
                  <td>{index + 1}</td>
                  <td>
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "100%",
                      }}
                      src={e.companyImage}
                    />
                  </td>
                  <td>{e.companyName}</td>
                  <td>{e.companyCategoryName}</td>
                  <td>{e.companyPhone}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={handleAktivate}
                      cmid={e.companyId}
                    >
                      Sil
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={handleAktivate}
                      cmid={e.companyId}
                    >
                      Sil
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Companies;
